import React from 'react';
import CookieConsent from '../cookie-consent';
import { useCookies } from 'react-cookie';
import { useSessionContext } from '../../../util/session.context';

const getExpiryDate = (): Date => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

const CookieConsentController = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const {
    openCookieConsent,
    closeCookieConsent,
    setCookieConsentStatus,
    cookieConsentStatus,
  } = useSessionContext();

  React.useEffect(() => {
    if (cookies['sco-consent'] === undefined) {
      openCookieConsent();
      clearNonEssentialCookies();
    } else if (cookies['sco-consent'] === true) {
      setCookieConsentStatus(true);
    } else {
      setCookieConsentStatus(false);
      clearNonEssentialCookies();
    }
  }, []);

  const setCookieAccept = (close?: boolean) => {
    setCookie('sco-consent', true, { expires: getExpiryDate() });
    setCookieConsentStatus(true);
    if (close) {
      closeCookieConsent();
    }
  };

  const setCookieReject = (close?: boolean) => {
    setCookie('sco-consent', false, { expires: getExpiryDate() });
    setCookieConsentStatus(false);
    if (close) {
      closeCookieConsent();
    }
    clearNonEssentialCookies();
  };

  const clearNonEssentialCookies = () => {
    const allCookies = Object.keys(cookies);

    for (const cookie of allCookies) {
      if (cookie !== 'sco-consent') {
        removeCookie(cookie, { path: '/', domain: window.location.hostname });
        removeCookie(cookie, { path: '/', domain: `.${window.location.hostname}` });
      }
    }
  }

  return (
    <CookieConsent
      mode={
        cookieConsentStatus === true
          ? 'amend-consent'
          : cookieConsentStatus === false
            ? 'amend-reject'
            : 'initial'
      }
      onAccept={setCookieAccept}
      onReject={setCookieReject}
    />
  );
};

export default CookieConsentController;
