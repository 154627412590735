import { StyleSheet } from 'aphrodite';

const contactIntroductionStyles = StyleSheet.create({
  image: {
    maxWidth: '300px',
    objectFit: 'contain',
  },
  imageContainer: {
    width: '30%',
  },
});

export default contactIntroductionStyles;
