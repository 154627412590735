import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const volunteerStyles = StyleSheet.create({
  registerBackground: {
    backgroundColor: theme.colour.yellow,
  },
  registerSubtitle: {
    fontSize: 'calc(1.1vw + 1.1vh)',

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.small}px)`]:
      {
        fontSize: 'calc(1.5vw + 1.5vh)',
      },

    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 'calc(1.8vw + 1.8vh)',
    },
  },
  positionsCardContainer: {
    display: 'grid',
    gap: theme.size.medium,
  },
  positionsCardContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.size.medium,
  },
});

export default volunteerStyles;
