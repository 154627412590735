import React from 'react';

interface SessionContextType {
  isMobileMenuOpen: boolean;
  openMobileMenu: () => void;
  closeMobileMenu: () => void;
  isSectionDropdownOpen: boolean;
  openSectionDropdown: () => void;
  closeSectionDropdown: () => void;
  isCookieConsentOpen: boolean;
  openCookieConsent: () => void;
  closeCookieConsent: () => void;
  cookieConsentStatus?: boolean;
  setCookieConsentStatus: (consent: boolean) => void;
}

const SessionContext = React.createContext<SessionContextType | undefined>(
  undefined,
);

export const useSessionContext = () => {
  const context = React.useContext(SessionContext);

  if (!context) {
    throw new Error(
      'useSessionContext must be used within a SessionContextProvider',
    );
  }

  return context;
};

export const SessionContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [isSectionDropdownOpen, setIsSectionDropdownOpen] =
    React.useState(false);
  const [isCookieConsentOpen, setIsCookieConsentOpen] = React.useState(false);
  const [cookieConsentStatus, setCookieConsentStatus] = React.useState<
    boolean | undefined
  >(undefined);

  const openMobileMenu = () => {
    document.body.style.overflow = 'hidden';
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    document.body.style.overflow = 'auto';
    setIsMobileMenuOpen(false);
  };

  const openSectionDropdown = () => {
    setIsSectionDropdownOpen(true);
  };

  const closeSectionDropdown = () => {
    setIsSectionDropdownOpen(false);
  };

  const openCookieConsent = () => {
    setIsCookieConsentOpen(true);
  };

  const closeCookieConsent = () => {
    setIsCookieConsentOpen(false);
  };

  return (
    <SessionContext.Provider
      value={{
        isMobileMenuOpen,
        openMobileMenu,
        closeMobileMenu,
        isSectionDropdownOpen,
        openSectionDropdown,
        closeSectionDropdown,
        isCookieConsentOpen,
        openCookieConsent,
        closeCookieConsent,
        cookieConsentStatus,
        setCookieConsentStatus,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
