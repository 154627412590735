import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const modalStyles = StyleSheet.create({
  backdrop: {
    position: 'fixed',
    top: 0,
    zIndex: theme.layers.modal,
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  modal: {
    maxWidth: '80%',
    backgroundColor: 'white',
    borderRadius: theme.size.small,
  },
  titleBar: {
    borderBottom: `1px solid ${theme.colour.grey[20]}`,
    padding: `${theme.size.small} ${theme.size.medium}`,
    borderRadius: `${theme.size.small} ${theme.size.small} 0 0`,
  },
  childrenContainer: {
    padding: theme.size.medium,
    overflowY: 'auto',
    maxHeight: '60vh',
  }
});

export default modalStyles;
