import { css } from 'aphrodite';
import React from 'react';
import menuDropdownStyles from './menu-dropdown.styles';
import { commonStyles } from '../../../styles/common.styles';
import { Link } from 'react-router-dom';
import { Page } from '../../../types/navigation';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { useSessionContext } from '../../../util/session.context';

type MenuDropdownItemProps = {
  index: number;
  hoveredItem?: number;
  setHoveredItem: (index?: number) => void;
  isDropdownHover: boolean;
  label: string;
  sublabel?: string;
  onClick?: () => void;
  onBlur?: () => void;
  link: string;
  visible?: boolean;
};

const MenuDropdownItem = ({
  index,
  hoveredItem,
  setHoveredItem,
  isDropdownHover,
  label,
  sublabel,
  onClick,
  onBlur,
  link,
  visible,
}: MenuDropdownItemProps) => {
  return (
    <Link to={link} className={css(commonStyles.nativeReset)} tabIndex={-1}>
      <button
        className={css(
          commonStyles.nativeReset,
          menuDropdownStyles.itemContainer,
          visible === false && menuDropdownStyles.inactiveCursor,
          (hoveredItem !== undefined || isDropdownHover) &&
            hoveredItem !== index &&
            menuDropdownStyles.inactive,
        )}
        onMouseOver={() => setHoveredItem(index)}
        onFocus={() => setHoveredItem(index)}
        onMouseLeave={() => setHoveredItem(undefined)}
        onBlur={() => {
          setHoveredItem(undefined);
          if (onBlur) {
            onBlur();
          }
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <div style={{ fontSize: '22px' }}>{label}</div>
        {sublabel && (
          <div
            className={css(commonStyles.noWrapText)}
            style={{ fontSize: '16px' }}
          >
            {sublabel}
          </div>
        )}
      </button>
    </Link>
  );
};

export type MenuDropdownProps = {
  onDropdownHover?: () => void;
  onDropdownUnhover?: () => void;
};

const MenuDropdown = ({
  onDropdownHover,
  onDropdownUnhover,
}: MenuDropdownProps) => {
  const [hoveredItem, setHoveredItem] = React.useState<number | undefined>(
    undefined,
  );

  const { isSectionDropdownOpen: isOpen, closeSectionDropdown } =
    useSessionContext();

  const [isDropdownHover, setIsDropdownHover] = React.useState(false);

  React.useEffect(() => {
    if (isDropdownHover && onDropdownHover) {
      onDropdownHover();
    } else if (!isDropdownHover && onDropdownUnhover) {
      onDropdownUnhover();
    }
  }, [isDropdownHover]);

  const { isScreenScrolled } = useScreenSizeContext();

  const handleItemClick = () => {
    closeSectionDropdown();
    setIsDropdownHover(false);
  };

  return (
    <div
      className={css(
        commonStyles.relative,
        menuDropdownStyles.fade,
        isOpen || isDropdownHover
          ? menuDropdownStyles.visible
          : menuDropdownStyles.hidden,
      )}
    >
      <div
        className={css(menuDropdownStyles.parentContainer)}
        onMouseOver={() => setIsDropdownHover(true)}
        onMouseLeave={() => setIsDropdownHover(false)}
      >
        <div
          className={css(
            menuDropdownStyles.container,
            commonStyles.shadow,
            isScreenScrolled
              ? menuDropdownStyles.containerScrolled
              : menuDropdownStyles.containerAtTop,
            menuDropdownStyles.containerFade,
            !isOpen && !isDropdownHover && menuDropdownStyles.containerHidden,
          )}
        >
          <div
            className={css(
              commonStyles.flex,
              commonStyles.flexColumn,
              commonStyles.bold,
              commonStyles.gapMedium,
            )}
          >
            <MenuDropdownItem
              index={0}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isDropdownHover={isDropdownHover}
              label='Squirrels'
              sublabel='Ages 4-6'
              onClick={handleItemClick}
              link={Page.Squirrels}
              visible={isOpen || isDropdownHover}
            />
            <MenuDropdownItem
              index={1}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isDropdownHover={isDropdownHover}
              label='Beavers'
              sublabel='Ages 6-8'
              onClick={handleItemClick}
              link={Page.Beavers}
              visible={isOpen || isDropdownHover}
            />
            <MenuDropdownItem
              index={2}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isDropdownHover={isDropdownHover}
              label='Cubs'
              sublabel='Ages 8-10½'
              onClick={handleItemClick}
              link={Page.Cubs}
              visible={isOpen || isDropdownHover}
            />
            <MenuDropdownItem
              index={3}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isDropdownHover={isDropdownHover}
              label='Scouts'
              sublabel='Ages 10½-14'
              onClick={handleItemClick}
              link={Page.Scouts}
              visible={isOpen || isDropdownHover}
            />
            <MenuDropdownItem
              index={4}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isDropdownHover={isDropdownHover}
              label='Explorers'
              sublabel='Ages 14-18'
              onClick={handleItemClick}
              link={Page.Explorers}
              visible={isOpen || isDropdownHover}
            />
            <MenuDropdownItem
              index={5}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isDropdownHover={isDropdownHover}
              label='Network'
              sublabel='Ages 18-25'
              onClick={handleItemClick}
              onBlur={() => {
                closeSectionDropdown();
                setIsDropdownHover(false);
              }}
              link={Page.Network}
              visible={isOpen || isDropdownHover}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuDropdown;
