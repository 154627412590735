import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { theme } from '../../../../styles/theme';
import { commonStyles } from '../../../../styles/common.styles';
import footerStyles from '../footer.styles';
import { css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSessionContext } from '../../../../util/session.context';

export type SocialIconProps = {
  icon: IconDefinition;
  ariaLabel: string;
  link: string;
  color?: string;
  size?: SizeProp;
};

const SocialIcon = ({
  icon,
  ariaLabel,
  link,
  color = theme.colour.white,
  size = 'xl',
}: SocialIconProps) => {
  const { isMobileMenuOpen } = useSessionContext();

  return (
    <a
      href={link}
      target='_blank'
      rel='noreferrer'
      aria-label={ariaLabel}
      tabIndex={-1}
    >
      <button
        className={css(commonStyles.nativeReset, footerStyles.socialIconHover)}
        title={ariaLabel}
        tabIndex={isMobileMenuOpen ? -1 : 0}
      >
        <FontAwesomeIcon icon={icon} color={color} size={size} />
      </button>
    </a>
  );
};

export default SocialIcon;
