import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

const homeSheepfairStyles = StyleSheet.create({
  largeImageContainer: {
    width: '50%',
  },
  smallImageContainer: {
    marginLeft: theme.size.large,
  },
  image: {
    maxWidth: '530px',
    objectFit: 'contain',
  },
  contentContainerLarge: {
    width: '50%',
    textAlign: 'right',
  },
});

export default homeSheepfairStyles;
