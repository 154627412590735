export const theme = {
  colour: {
    black: '#000000',
    white: '#FFFFFF',
    purple: '#7413DC',
    teal: '#088486',
    grey: {
      20: '#CCCCCC',
      40: '#999999',
      60: '#666666',
      80: '#333333',
    },
    red: '#ED3F23',
    pink: '#FFB4E5',
    green: '#25B755',
    navy: '#003982',
    blue: '#006DDF',
    yellow: '#FFE627',
    orange: '#FF912A',
    forestGreen: '#205B41',
    offWhite: '#FDFDFD',
  },
  size: {
    xxxsmall: '4px',
    xxsmall: '8px',
    xsmall: '12px',
    small: '16px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
    xxlarge: '64px',
    xxxlarge: '128px',
  },
  breakpoints: {
    xlarge: 1440,
    large: 1024,
    medium: 840,
    small: 512,
  },
  layers: {
    hidden: -10,
    imageText: 10,
    navbar: 100,
    cookie: 150,
    mobileNav: 200,
    modal: 300,

  },
  margins: {
    screenEdges: '4vw',
  },
};
