import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import generalUtil from '../../util/general';
import SectionIntroduction from './shared/introduction/section-introduction';
import SectionDetails from './shared/details/section-details';
import { SectionDetailData } from '../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import SectionDetailsBadgePlacement from './shared/details/badge-placement/section-details-badge-placement';
import SectionDetailsPromise from './shared/details/promise/section-details-promise';
import SectionDetailsSafeguarding from './shared/details/safeguarding/section-details-safeguarding';
import SectionDetailsMoreInformation from './shared/details/more-information/section-details-more-information';
import SectionDetailsWhereWhen from './shared/details/where-when/section-details-where-when';
import SectionDetailsMovingOn from './shared/details/moving-on/section-details-moving-on';
import SectionContact from './shared/contact/section-contact';
import Button from '../../components/button/button';
import { CustomIconName } from '../../components/custom-icon/custom-icons';
import { useScreenSizeContext } from '../../util/screensize.context';
import applicationVariables from '../../util/variables';
import { useSessionContext } from '../../util/session.context';

const Explorers = () => {
  const [openCardIndexes, setOpenCardIndexes] = React.useState<number[]>([]);

  const { isScreenXSmall } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();

  const detailData: SectionDetailData = [
    {
      question: 'What do Explorers do?',
      answer: (
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapMedium,
          )}
        >
          <div>
            <b className={css(commonStyles.text)}>Discovering the world</b>
            <p className={css(commonStyles.marginTopXXXSmall)}>
              Being an Explorer is all about discovering the world on your own
              terms and making the most of what you have, wherever and whoever
              you are.
            </p>
            <p>
              Alongside your new friends, you&apos;ll master the skills that
              will make you feel stronger and happier in the long run, and try
              things you&apos;d never get the chance to do at home or at school.
            </p>
            <p className={css(commonStyles.noMarginBottom)}>
              Whether you&apos;re hiking to faraway lands or building a robot in
              your local town hall, you&apos;ll have the freedom to choose what
              you&apos;d like to do, and work together with adults to make it
              happen.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>Start small but think big</b>
            <p className={css(commonStyles.marginTopXXXSmall)}>
              Explorers start small but think big.
            </p>
            <p className={css(commonStyles.noMarginBottom)}>
              They stand up for what they believe and make a difference on their
              own doorstops, confident in the knowledge that their daily actions
              add up.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>
              Seek out the answers to big questions
            </b>
            <p className={css(commonStyles.marginTopXXXSmall)}>
              Explorers seek out the answers to the big questions, and to the
              smaller questions that don&apos;t seem to matter but really
              should.
            </p>
            <p className={css(commonStyles.noMarginBottom)}>
              Most importantly, they say yes more often than they say no -
              whether they&apos;re signing up for their first major hike across
              Europe, or writing their first line of code, or accepting the last
              of the toasted marshmallows.
            </p>
          </div>
        </div>
      ),
    },
    {
      question: 'Who can join Explorers?',
      answer: `Anyone between the ages of 14 and 18, whether they've been part of any younger section or not. Here at Scouts, we're open to people of all genders, backgrounds, and faiths (including people of no faith). We're committed to reaching out to new and different families and communities and including people who have never tried Scouts before.`,
    },
    {
      question: 'Where & when do Explorers meet?',
      answer: (
        <SectionDetailsWhereWhen
          fullSectionName='Explorers'
          dateTime='Thursdays, from 19:30 - 21:30'
          isVisible
        />
      ),
    },
    {
      question: 'Do Explorers have a uniform?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            Once they&apos;ve had time to settle in to the group, Explorers get
            their own set of uniform to wear during meetings and on trips away.
            Usually, this consists of a beige shirt or blouse with their badges
            sewn on, which they pair with their Unit scarf.
          </p>
          <p>
            Alongside their shirts, Explorers might wear the accompanying blue
            uniform trousers or skirt, or they might save their uniform bottoms
            to wear for special occasions like awards ceremonies and public
            events - choosing to wear something more casual with their shirt
            during the week. Optional accessories such as hats, hoodies, are
            also available.
          </p>
          <p>
            Uniform can either be bought from the official Scout Store or from a
            local supplier (such as Uniforms Plus). If you&apos;re not sure
            where to start, or could use a little help with uniform costs, fear
            not. The Explorer leader can chat to you about options. The most
            important thing when you first arrive is wearing something that you
            feel natural and comfortable in.
          </p>
          <div
            className={css(
              commonStyles.flex,
              isScreenXSmall ? commonStyles.gapSmall : commonStyles.gapLarge,
              isScreenXSmall && commonStyles.flexColumn,
            )}
          >
            <Button
              label='Scout Shop'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.scoutShopUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
            <Button
              label='Uniforms Plus'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.uniformVendorUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
          </div>
        </>
      ),
    },
    {
      question: 'Where do badges go?',
      answer: (
        <SectionDetailsBadgePlacement
          section='Explorers'
          isVisible={openCardIndexes.includes(1)}
        />
      ),
    },
    {
      question: 'What is an investiture?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            Every Explorer is unique, but they find common ground in their
            shared Scout values, and make a promise to stick by them.
          </p>
          <p>
            Making a promise when you join the Unit is a way of celebrating
            these values.
          </p>
          <p>
            Every time a new Explorer decides to join, they chat through their
            promise with their leader before saying it out loud in front of
            their fellow Explorers. Family and friends might come along to see
            this, too. The process usually takes place once you&apos;ve had a
            few weeks to settle in, and is known as being &quot;invested&quot;
            into Explorers.
          </p>
          <p className={css(commonStyles.noMarginBottom)}>
            It could be held in your usual meeting place, or it could happen
            around the campfire, or it could happen on a boat sailing the seven
            seas. Regardless, it&apos;s a big celebration for all involved, and
            it&apos;s not uncommon for family and friends to join your fellow
            Scouts as they cheer you on.
          </p>
        </>
      ),
    },
    {
      question: 'What is the Explorer Scout Promise?',
      answer: (
        <SectionDetailsPromise
          section='Explorers'
          introduction='Explorers choose the promise that best suits them.'
          data={[
            {
              variant: 'Atheist or of no faith background',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to uphold our Scout values, to do my duty to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Buddhist',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to seek refuge in the Triple Gem, to do my duty to The King,',
                'to act with compassion towards all life',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Christian',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to do my duty to God and to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Hindu',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to follow my dharma and do my duty to The King,',
                'to act with compassion towards all life',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Humanist',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to uphold our Scout values, to do my duty to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Jewish',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to do my duty to God and to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Muslim',
              promise: [
                'In the name of Allah, the most beneficent and the most merciful,',
                'I promise that I will do my best',
                'to do my duty to Allah and then to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Sikh',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to do my duty to Waheguru and to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
          ]}
        />
      ),
    },
    {
      question: `I'm concerned about safety/safeguarding`,
      answer: (
        <SectionDetailsSafeguarding isVisible={openCardIndexes.includes(4)}>
          <p>
            All of our leaders are trained volunteers, working to make sure
            Scouting is safe and open to all. The programme delivered is
            specifically designed to be accessible for all those aged between 14
            and 18.
          </p>
        </SectionDetailsSafeguarding>
      ),
    },
    {
      question: 'What happens after Explorers?',
      answer: (
        <SectionDetailsMovingOn
          section='Explorers'
          isVisible={openCardIndexes.includes(5)}
        />
      ),
    },
    {
      question: `I'd like more information`,
      answer: (
        <SectionDetailsMoreInformation
          section='Explorers'
          isVisible={openCardIndexes.includes(6)}
        />
      ),
    },
  ];

  const bgImage = React.useMemo(
    () =>
      generalUtil.getRandomEntryFromArray(
        media.images.sections.explorers.header,
      ),
    [],
  );

  return (
    <>
      <Header
        image={bgImage}
        title='Explorers'
        subtitle='Ages 14 - 18'
        titleDesc='The Stags Explorer Scout Unit'
        backgroundPosition={bgImage.pos}
        subImage={media.brand.group.sesu.white}
      />
      <SectionIntroduction
        heading={`Try more. See more. Do more. At Explorers you'll get the chance to dream big, find your place in the world and make memories to last a lifetime.`}
        image={media.images.sections.explorers.introduction}
      >
        <>
          <p>
            Week in and week out, they gather in groups called Units to try new
            things, make new friends and conquer the small task of changing the
            world.
          </p>
          <p>
            Explorers are encouraged to lead themselves in deciding the
            programme and direction of the Unit, with support and guidance from
            leaders. There is wider scope for activities like offshore sailing,
            campaigning, performing, parascending, mountaineering and
            expeditions.
          </p>
          <p>
            The section also includes the Young Leaders&apos; Scheme, where
            young people are able to take on a leadership role in one of the
            younger sections.
          </p>
          <p>
            Sound like fun? That&apos;s because it is. All that&apos;s missing
            is you.
          </p>
        </>
      </SectionIntroduction>
      <SectionDetails
        data={detailData}
        formation='pyramid'
        onCardChange={setOpenCardIndexes}
      />
      <SectionContact section='Explorers' />
    </>
  );
};

export default Explorers;
