import React from 'react';
import { OpenPosition } from '../../../../types/general';
import { PositionModalColourScheme } from '../position-card/position-card';
import Modal from '../../../../components/modal/modal';
import { css } from 'aphrodite';
import Button from '../../../../components/button/button';
import { CustomIconName } from '../../../../components/custom-icon/custom-icons';
import { commonStyles } from '../../../../styles/common.styles';
import { Page } from '../../../../types/navigation';

export type PositionDetailsModalProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  position: OpenPosition;
  colourScheme: PositionModalColourScheme;
};

const PositionDetailsModal = ({
  isOpen,
  setIsOpen,
  position: { section, title, outline, mainTasks},
  colourScheme,
}: PositionDetailsModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title={section === 'General' ? title : `${title} - ${section}`}
      bannerColour={colourScheme.bg}
      bannerTextColour={colourScheme.text}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          commonStyles.gapMedium,
        )}
      >
        <div>
          <h4 className={css(commonStyles.largeText, commonStyles.noMargin)}>
            Outline of the role
          </h4>
          <p className={css(commonStyles.noMargin)}>{outline}</p>
        </div>
        <div>
          <h4 className={css(commonStyles.largeText, commonStyles.noMargin)}>
            Main Tasks
          </h4>
          <p className={css(commonStyles.noMargin)}>
            <ul className={css(commonStyles.noMargin)}>
              {mainTasks.map((task, index) => (
                <li key={index}>{task}</li>
              ))}
            </ul>
          </p>
        </div>
        <div>
          <h4 className={css(commonStyles.largeText, commonStyles.noMargin)}>
            Interested?
          </h4>
          <p className={css(commonStyles.noMarginTop)}>
            If you think you&apos;ve got what it takes to fulfil this role,
            contact our Group Scout Leader, Joanne Carter, to have an informal
            discussion about the role and to answer any questions you may have.
          </p>
          <Button
            label='Contact GSL'
            fontSize='20px'
            icon={CustomIconName.ArrowCircleRight}
            link={`${Page.Contact}#gsl`}
            borderOnHover
            variant={colourScheme.button}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PositionDetailsModal;
