import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../../styles/common.styles';
import openPositions from '../../../util/data/positions';
import { media } from '../../../util/media';
import homeSectionsStyles from '../../home/sections/home-sections.styles';
import PositionCard from './position-card/position-card';
import { useScreenSizeContext } from '../../../util/screensize.context';
import Button from '../../../components/button/button';
import { CustomIconName } from '../../../components/custom-icon/custom-icons';
import { Page } from '../../../types/navigation';
import volunteerStyles from '../volunteer.styles';
import { useSessionContext } from '../../../util/session.context';

const VolunteerPositions = () => {
  const { isScreenSmallOrLess, isScreenXSmall } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();

  return (
    <div className={css(commonStyles.flex, commonStyles.centreHorizontal)}>
      <div
        className={css(
          commonStyles.maxScreenWidth,
          commonStyles.fullWidth,
          !isScreenSmallOrLess
            ? homeSectionsStyles.sectionContainer
            : commonStyles.screenMargin,
        )}
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.fitContentWidth,
          )}
        >
          <h2
            className={css(
              commonStyles.pageSectionHeading,
              commonStyles.noMargin,
            )}
          >
            We&apos;re Scouting for Talent
          </h2>
          <img
            src={media.images.index.underline.src}
            alt={media.images.index.underline.alt}
            width={'100%'}
          />
        </div>
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.centreVertical,
          )}
        >
          <div className={css(commonStyles.fullWidth)}>
            <div className={css(commonStyles.marginTopLarge)}>
              {openPositions.length === 0 ? (
                <div>
                  <p>
                    We&apos;re always looking for additional leaders to help in
                    any of our sections! Whatever your skillset, whether you
                    take a committed or casual role, we&apos;d appreciate any
                    time you can spare.
                  </p>
                  <p>
                    To get started, please register your interest in
                    volunteering with us below. Alternatively, if you have any
                    questions before registering, please contact our Group Scout
                    Leader, Joanne Carter.
                  </p>
                  <div className={css(commonStyles.marginTopLarge)}>
                    <Button
                      label='Contact GSL'
                      fontSize='20px'
                      icon={CustomIconName.ArrowCircleRight}
                      link={`${Page.Contact}#gsl`}
                      borderOnHover
                      focusable={!isMobileMenuOpen}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <p>
                    We&apos;re currently looking for volunteers to fill the
                    following positions:
                  </p>
                  <div>
                    <div
                      className={css(
                        isScreenXSmall
                          ? volunteerStyles.positionsCardContainerMobile
                          : volunteerStyles.positionsCardContainer,
                      )}
                      style={{
                        gridTemplateColumns: `repeat(${isScreenSmallOrLess ? '2' : '3'}, minmax(0, 1fr))`,
                      }}
                    >
                      {openPositions.map((positionData, index) => (
                        <PositionCard
                          position={positionData}
                          key={`2ndrug-position-${index}`}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerPositions;
