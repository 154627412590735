import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import generalUtil from '../../util/general';
import SectionIntroduction from './shared/introduction/section-introduction';
import SectionDetails from './shared/details/section-details';
import { SectionDetailData } from '../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import SectionDetailsBadgePlacement from './shared/details/badge-placement/section-details-badge-placement';
import SectionDetailsPromise from './shared/details/promise/section-details-promise';
import SectionDetailsSafeguarding from './shared/details/safeguarding/section-details-safeguarding';
import SectionDetailsMoreInformation from './shared/details/more-information/section-details-more-information';
import SectionDetailsWhereWhen from './shared/details/where-when/section-details-where-when';
import SectionDetailsMovingOn from './shared/details/moving-on/section-details-moving-on';
import SectionContact from './shared/contact/section-contact';
import Button from '../../components/button/button';
import { CustomIconName } from '../../components/custom-icon/custom-icons';
import { useScreenSizeContext } from '../../util/screensize.context';
import applicationVariables from '../../util/variables';
import { useSessionContext } from '../../util/session.context';

const Scouts = () => {
  const [openCardIndexes, setOpenCardIndexes] = React.useState<number[]>([]);

  const { isScreenXSmall } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();

  const detailData: SectionDetailData = [
    {
      question: 'What do Scouts do?',
      answer: (
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapMedium,
          )}
        >
          <div>
            <b className={css(commonStyles.text)}>Discovering the world</b>
            <p className={css(commonStyles.marginTopXXXSmall)}>
              Being a Scout is all about discovering the world on your own terms
              and making the most of what you have, wherever and whoever you
              are.
            </p>
            <p className={css(commonStyles.noMarginBottom)}>
              Alongside your new friends, you&apos;ll master the skills
              that&apos;ll help you weather the storms of life, and try things
              you&apos;d never get the chance to do at home or at school -
              working with trained volunteers to achieve whatever you set your
              mind to.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>
              Starting small, thinking big
            </b>
            <p className={css(commonStyles.marginTopXXXSmall)}>
              Scouts start small but think big. They stand up for what they
              believe in and make a difference on their doorsteps, confident in
              the knowledge that their daily actions add up.
            </p>
            <p>
              In a society that can often feel increasingly isolated and inward
              facing, Scouts build bridges and break barriers.
            </p>
            <p className={css(commonStyles.noMarginBottom)}>
              Throughout history, they&apos;ve played all sorts of useful roles in
              society, and this legacy continues today.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>
              Listening in, lending a hand
            </b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Scouts seek out the answers to the big questions, and to the
              smaller questions that don&apos;t seem to matter but really
              should. Most importantly, they say yes more often than they say no
              - whether they&apos;re taking part in their first ever camp away
              from home, writing their first line of code, or accepting the last
              of the toasted marshmallows.
            </p>
          </div>
        </div>
      ),
    },
    {
      question: 'Who can join Scouts?',
      answer: `Anyone between the ages of 10½ and 14, whether they've been part of any younger section or not. Here at Scouts, we're open to people of all genders, backgrounds, and faiths (including people of no faith). We’re committed to reaching out to new and different families and communities and including people who have never tried Scouts before.`,
    },
    {
      question: 'Where & when do Scouts meet?',
      answer: (
        <SectionDetailsWhereWhen
          fullSectionName='Scouts'
          dateTime='Mondays, from 19:00 - 21:00'
          isVisible
        />
      ),
    },
    {
      question: 'Do Scouts have a uniform?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            Scouts wear a uniform during their weekly meetings and sometimes on
            trips away, depending on where they&apos;re going and what
            they&apos;re doing. Usually, this consists of a teal green shirt or
            blouse with their badges sewn on, which they pair with a scarf,
            known as a necker.
          </p>
          <p>
            Alongside their shirts, Scouts might wear the accompanying blue
            uniform trousers or skirt, or they might save their uniform bottoms
            to wear for special occasions like awards ceremonies and public
            events - choosing to wear something more casual with their shirt
            during the week. Optional accessories such as hats, hoodies, are
            also available.
          </p>
          <p>
            Uniform can either be bought from the official Scout Store or from a
            local supplier (such as Uniforms Plus). If you&apos;re not sure where
            to start, or could use a little help with uniform costs, fear not.
            The Scout leader can chat to you about options. The most important
            thing when you first arrive is wearing something that you feel
            natural and comfortable in.
          </p>
          <div
            className={css(
              commonStyles.flex,
              isScreenXSmall ? commonStyles.gapSmall : commonStyles.gapLarge,
              isScreenXSmall && commonStyles.flexColumn,
            )}
          >
            <Button
              label='Scout Shop'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.scoutShopUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
            <Button
              label='Uniforms Plus'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.uniformVendorUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
          </div>
        </>
      ),
    },
    {
      question: 'Where do badges go?',
      answer: (
        <SectionDetailsBadgePlacement
          section='Scouts'
          isVisible={openCardIndexes.includes(1)}
        />
      ),
    },
    {
      question: 'What is an investiture?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            Every Scout is unique, but they find common ground in their shared
            Scout values, and make a promise to stick by them.
          </p>
          <p>
            Making a promise when you join the Troop is a way of celebrating
            these values. Every time a new Scout decides to join, they chat
            through their promise with their leader before saying it out loud in
            front of their fellow Scouts.
          </p>
          <p>
            The process usually takes place once you&apos;ve had a few weeks to
            settle in, and is known as being &quot;invested&quot; into Scouts.
            Usually, the promise ceremony happens in a place you&apos;ve chosen,
            or in a memorable place that means a lot to the group.
          </p>
          <p className={css(commonStyles.noMarginBottom)}>
            It could be held in your usual meeting place, or it could happen
            around the campfire, or it could happen on a boat sailing the seven
            seas. Regardless, it&apos;s a big celebration for all involved, and
            it&apos;s not uncommon for family and friends to join your fellow
            Scouts as they cheer you on.
          </p>
        </>
      ),
    },
    {
      question: 'What is the Scout Promise?',
      answer: (
        <SectionDetailsPromise
          section='Scouts'
          introduction='Scouts choose the promise that best suits them.'
          data={[
            {
              variant: 'Atheist or of no faith background',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to uphold our Scout values, to do my duty to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Buddhist',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to seek refuge in the Triple Gem, to do my duty to The King,',
                'to act with compassion towards all life',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Christian',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to do my duty to God and to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Hindu',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to follow my dharma and do my duty to The King,',
                'to act with compassion towards all life',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Humanist',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to uphold our Scout values, to do my duty to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Jewish',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to do my duty to God and to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Muslim',
              promise: [
                'In the name of Allah, the most beneficent and the most merciful,',
                'I promise that I will do my best',
                'to do my duty to Allah and then to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
            {
              variant: 'Sikh',
              promise: [
                'On my honour,',
                'I promise that I will do my best',
                'to do my duty to Waheguru and to The King,',
                'to help other people',
                'and to keep the Scout Law.',
              ],
            },
          ]}
        />
      ),
    },
    {
      question: `I'm concerned about safety/safeguarding`,
      answer: (
        <SectionDetailsSafeguarding isVisible={openCardIndexes.includes(4)}>
          <p>
            All of our leaders are trained volunteers, working to make sure
            Scouting is safe and open to all. The programme delivered is
            specifically designed to be accessible for all members of the Scout
            section.
          </p>
        </SectionDetailsSafeguarding>
      ),
    },
    {
      question: 'What happens after Scouts?',
      answer: (
        <SectionDetailsMovingOn
          section='Scouts'
          isVisible={openCardIndexes.includes(5)}
        />
      ),
    },
    {
      question: `I'd like more information`,
      answer: (
        <SectionDetailsMoreInformation
          section='Scouts'
          isVisible={openCardIndexes.includes(6)}
        />
      ),
    },
  ];

  const bgImage = React.useMemo(
    () =>
      generalUtil.getRandomEntryFromArray(media.images.sections.scouts.header),
    [],
  );

  return (
    <>
      <Header
        image={bgImage}
        title='Scouts'
        subtitle='Ages 10½ - 14'
        backgroundPosition={bgImage.pos}
      />
      <SectionIntroduction
        heading='Jump in and get muddy. Give back and get set. Scouts ignore the butterflies and go for it, and soon so will you.'
        image={media.images.sections.scouts.introduction}
      >
        <>
          <p>
            Week in and week out, they gather in groups called Scout Troops to
            conquer the small task of changing the world.
          </p>
          <p>
            Scouts enjoy meeting new people and making friends, whilst
            participating in a variety of exciting activities, from climbing and
            abseiling, to go-karting and archery. Scouts are also taught
            traditional Scouting skills, from fire-lighting to cooking and
            survival.
          </p>
          <p>
            Scouts are at an age where they&apos;re starting to stand on their
            own two feet. Our leaders guide them as they learn expand their
            abilities, whilst exploring their personal values and attitudes.
          </p>
          <p>
            Sound like fun? That&apos;s because it is. All that&apos;s missing
            is you.
          </p>
        </>
      </SectionIntroduction>
      <SectionDetails
        data={detailData}
        formation='pyramid'
        onCardChange={setOpenCardIndexes}
      />
      <SectionContact section='Scouts' />
    </>
  );
};

export default Scouts;
