import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import SectionIntroduction from '../sections/shared/introduction/section-introduction';
import VolunteerPositions from './positions/volunteer-positions';
import { useScreenSizeContext } from '../../util/screensize.context';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import volunteerStyles from './volunteer.styles';
import Button from '../../components/button/button';
import { CustomIconName } from '../../components/custom-icon/custom-icons';
import applicationVariables from '../../util/variables';
import { useSessionContext } from '../../util/session.context';

const Volunteer = () => {
  const { isScreenSmallOrLess, isScreenXSmall } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();
  return (
    <>
      <Header title='Volunteer with us' image={media.images.volunteer.header} />
      <SectionIntroduction
        heading={`Whatever your background, identity or ability, there's a volunteer spot with your name on it.`}
        image={media.images.volunteer.introduction}
        customMargin={isScreenSmallOrLess ? undefined : '4vw 4vw 0 4vw'}
      >
        <>
          <p>At 2nd Rugeley we celebrate difference and welcome everyone.</p>
          <p>
            Volunteering can be a brilliant way to boost your mental wellbeing.
            It&apos;s a great feeling knowing you&apos;re helping young people
            get skills for life, and you might even learn a lot about yourself
            along the way.
          </p>
          <p>
            Make friends and meet new people. Try new things and challenge
            yourself. Get outdoors and get stuck in! All whilst having a
            positive impact on your local community and creating vital
            opportunities for young people. Besides, you&apos;ll make memories
            yourself - memories that&apos;ll last a lifetime.
          </p>
          <p>
            If you can only occasionally help, that&apos;s OK. Whether
            you&apos;d like to be an activity planner, tea-maker or craft
            creator, we appreciate any time you can spare.
          </p>
        </>
      </SectionIntroduction>
      <VolunteerPositions />
      <div
        className={css(
          commonStyles.screenPadding,
          volunteerStyles.registerBackground,
          commonStyles.flex,
          commonStyles.centreHorizontal,
        )}
      >
        <div className={css(commonStyles.maxScreenWidth)}>
          <h2
            className={css(
              commonStyles.pageSectionHeading,
              commonStyles.noMargin,
            )}
          >
            Register Your Interest
          </h2>
          <h3
            className={css(
              volunteerStyles.registerSubtitle,
              isScreenXSmall
                ? commonStyles.marginTopXSmall
                : commonStyles.marginTopXXXSmall,
            )}
          >
            We&apos;d love to have you come along on our Scouting journey!
          </h3>
          <p>
            It&apos;s easy to register your interest in volunteering with us -
            simply fill in the form on our Scouting Management System (OSM), and
            someone will be in touch as soon as they can!
          </p>
          <div
            className={css(
              commonStyles.marginTopLarge,
              commonStyles.marginBottomLarge,
              commonStyles.flex,
              isScreenSmallOrLess && commonStyles.centreHorizontal,
            )}
          >
            <Button
              label='Register'
              icon={CustomIconName.ArrowCircleRight}
              variant='secondary-black'
              dropShadow
              bold
              fontSize='26px'
              link={applicationVariables.osmWaitListUrl}
              linkInNewTab
              focusable={!isMobileMenuOpen}
            />
          </div>
          <p>
            <i>
              Note: The form asks for &quot;Child&apos;s Details&quot;.
              Don&apos;t worry about this - this is just because we use the same
              form for all join requests, including young members. You&apos;ll
              need to input your own details in this section, and repeat them in
              the &quot;Your Details&quot; section
            </i>
          </p>
        </div>
      </div>
    </>
  );
};

export default Volunteer;
