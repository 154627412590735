import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const joinStyles = StyleSheet.create({
  cardsContainer: {
    margin: '3vw',
  },
  singleCardContainer: {
    padding: '1vw',
    height: 'calc(100% - 2vw)'
  },
  singleCardContainerHover: {
    padding: 0,
    height: '100%'
  },
  cardInnerPadding: {
    padding: '1vw',
  },
  subtitle: {
    width: '90%',
    fontSize: 'calc(1.2vw + 1.2vh)',
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 'calc(1.8vw + 1.8vh)',
    },
  },
  content: {
    height: 'calc(100% - 2vw)'
  },
  contentHover: {
    height: 'calc(100% - 4vw)',
    margin: '1vw',
  },
  note: {
    fontSize: 'calc(0.7vw + 0.7vh)',
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 'calc(1vw + 1vh)',
    },
  },
});

export default joinStyles;
