import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

const homeVolunteerStyles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: theme.colour.blue,
  },
  headingSmall: {
    width: '90%',
  },
  imageContainer: {
    marginTop: '-40px',
  },
  largeImageContainer: {
    width: '50%',
    marginTop: '-80px',
  },
  image: {
    maxWidth: '530px',
    objectFit: 'contain',
  },
  contentContainerLarge: {
    width: '50%',
  },
});

export default homeVolunteerStyles;
