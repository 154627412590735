import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const headerStyles = StyleSheet.create({
  container: {
    backgroundSize: 'cover',
    position: 'relative',
    transition: 'all 0.6s ease-in-out',
  },
  fullScreenHeight: {
    height: '100vh',
    minHeight: '400px',
    overflow: 'hidden',
  },
  halfScreenHeight: {
    height: '50vh',
    minHeight: '400px',
    overflow: 'hidden',
  },
  contentContainer: {
    color: theme.colour.white,
    zIndex: 10,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '6vw',
  },
  contentSubContainer: {
    width: '80%',
  },
  title: {
    fontSize: '10vw',
    [`@media (min-width: ${theme.breakpoints.large}px)`]: {
      fontSize: '6vw',
    },
  },
  subtitle: {
    fontSize: '3.5vw',
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: '4.5vw',
    },
    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.small}px)`]:
      {
        fontSize: '4vw',
      },
    [`@media (min-width: ${theme.breakpoints.large}px)`]: {
      fontSize: '2.2vw',
    },
  },
  titleDesc: {
    fontSize: '1.5vw',
    fontWeight: 500,
    marginTop: 0,
    marginBottom: theme.size.xsmall,

    [`@media (max-width: ${theme.breakpoints.xlarge}px) and (min-width: ${theme.breakpoints.large}px)`]:
      {
        fontSize: '1.8vw',
      },
    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.medium}px)`]:
      {
        fontSize: '2.5vw',
      },
    [`@media (max-width: ${theme.breakpoints.medium}px) and (min-width: ${theme.breakpoints.small}px)`]:
      {
        fontSize: '3vw',
      },
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: '3.2vw',
    },
  },
});

export default headerStyles;
