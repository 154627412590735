import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const cookieConsentSyles = StyleSheet.create({
  container: {
    backgroundColor: theme.colour.white,
    borderRadius: theme.size.small,
    border: `4px solid ${theme.colour.purple}`,
    position: 'fixed',
    bottom: theme.size.medium,
    left: theme.size.medium,
    padding: theme.size.medium,
    zIndex: theme.layers.cookie,
    transition: 'all 0.4s ease',
  },
  containerDesktop: {
    width: '600px',
  },
  containerMobile: {
    width: 'calc(100% - 96px)',
    left: '50%',
    bottom: '50%',
    transform: 'translate(-50%, 50%)',
  },
  title: {
    color: theme.colour.purple,
    fontSize: '42px',
    fontWeight: 800,
    margin: 0,
  },
  text: {
    fontSize: '18px',
  },
  open: {
    opacity: 1,
    visibility: 'visible',
  },
  closed: {
    opacity: 0,
    visibility: 'hidden',
  },
});

export default cookieConsentSyles;
