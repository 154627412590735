import React from 'react';
import { css } from 'aphrodite';
import homeStyles from './home.styles';
import { commonStyles } from '../../styles/common.styles';
import { useScreenSizeContext } from '../../util/screensize.context';
import HomeIntroduction from './introduction/home-introduction';
import HomeSections from './sections/home-sections';
import { media } from '../../util/media';
import HomeVolunteer from './volunteer/home-volunteer';
import HomeSheepfair from './sheepfair/home-sheepfair';
import generalUtil from '../../util/general';

const Home = () => {
  const { isScreenXSmall } = useScreenSizeContext();

  const mobileBgImage = React.useMemo(
    () => generalUtil.getRandomEntryFromArray(media.images.index.static),
    [],
  );

  return (
    <>
      <div className={css(homeStyles.container)}>
        {isScreenXSmall ? (
          <img src={mobileBgImage.src} width={'100%'} alt={mobileBgImage.alt} />
        ) : (
          <video
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            controlsList='nodownload'
            width={'100%'}
            height={'100%'}
          >
            <source src={media.video.index.src} type='video/mp4' />
          </video>
        )}

        <div
          className={css(
            homeStyles.headingTextStack,
            commonStyles.screenMargin,
          )}
        >
          <h1 className={css(commonStyles.noMargin)}>Do more.</h1>
          <h1 className={css(commonStyles.noMargin)}>Share more.</h1>
          <h1 className={css(commonStyles.noMargin)}>Be more.</h1>
        </div>
      </div>
      <HomeIntroduction />
      <HomeSections />
      <HomeVolunteer />
      <HomeSheepfair />
    </>
  );
};

export default Home;
