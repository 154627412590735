import { Metric } from "web-vitals";
import ReactGA from 'react-ga4';
import applicationVariables from "../variables";

export const initialiseGAForPerformance = () => {
    ReactGA.initialize(applicationVariables.gaMeasurementId);
}

export const logPerformance = ({ id, name, value}: Metric) => {
    ReactGA.event({
        category: 'Web Vitals',
        action: name,
        value: Math.round(name === 'CLS' ? value * 1000 : value),
        label: id,
        nonInteraction: true
    })
};

export const checkCookie = (domCookies: string) => {
    const cookies = domCookies.split(';');
    for (const cookie of cookies) {
            const [key, value] = cookie.split('=');
            if (key === 'sco-consent') {
                return value === 'true';
            }
    }

    return false;
}