import React from 'react';
import { PageMetadata } from '../types/general';
import { Helmet } from 'react-helmet';
import { metaDefault } from './data/meta';
import applicationVariables from './variables';

export type PageProps = {
  component: React.ReactElement;
  meta: PageMetadata;
};

const Page = ({ component, meta }: PageProps) => {
  const defaultTitle = '2nd Rugeley Scout Group';

  return (
    <>
      <Helmet>
        <title>
          {meta.title ? `${meta.title} | ${defaultTitle}` : defaultTitle}
        </title>
        <meta
          name='description'
          content={meta.description ?? metaDefault.description}
        />
        <link
          rel='canonical'
          href={`${applicationVariables.rootUrl}${meta.page}`}
        />
        <meta
          property='og:title'
          content={`${metaDefault.title} - ${defaultTitle}`}
        />
        <meta property='og:description' content={meta.description} />
        <meta
          property='og:url'
          content={`${applicationVariables.rootUrl}${meta.page}`}
        />
        <meta
          property='og:image'
          content={`${applicationVariables.rootUrl}${meta.openGraph?.img}`}
        />
        {meta.crawl === false && <meta name='robots' content='noindex' />}
      </Helmet>
      {component}
    </>
  );
};

export default Page;
