import React from 'react';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import getUnderlineLinkStyles from './underline-link.styles';
import { theme } from '../../styles/theme';
import { useSessionContext } from '../../util/session.context';

export type UnderlineLinkProps = {
  text: string;
  link?: string;
  linkInNewTab?: boolean;
  onClick?: () => void;
  textColour?: string;
  inline?: boolean;
};

const UnderlineLink = ({
  text,
  link,
  linkInNewTab = false,
  onClick,
  textColour = theme.colour.black,
  inline = false,
}: UnderlineLinkProps) => {
  const styles = React.useMemo(
    () => getUnderlineLinkStyles(textColour),
    [textColour],
  );

  const { isMobileMenuOpen } = useSessionContext();

  return inline ? (
    <span
      className={css(styles.underlineAnimationText, commonStyles.noWrapText)}
      onClick={onClick}
      tabIndex={link || isMobileMenuOpen ? -1 : 0}
    >
      {link ? (
        <a
          className={css(commonStyles.nativeReset)}
          href={link}
          target={linkInNewTab ? '_blank' : '_self'}
          rel='noreferrer'
          tabIndex={isMobileMenuOpen ? -1 : 0}
        >
          {text}
        </a>
      ) : (
        <>{text}</>
      )}
    </span>
  ) : (
    <>
      {link ? (
        <button
          className={css(commonStyles.nativeReset)}
          onClick={onClick}
          tabIndex={-1}
        >
          <a
            className={css(
              commonStyles.nativeReset,
              styles.underlineAnimationText,
              commonStyles.noWrapText,
            )}
            href={link}
            target={linkInNewTab ? '_blank' : '_self'}
            rel='noreferrer'
            tabIndex={isMobileMenuOpen ? -1 : 0}
          >
            {text}
          </a>
        </button>
      ) : (
        <button
          className={css(
            styles.underlineAnimationText,
            commonStyles.noWrapText,
            commonStyles.nativeReset,
          )}
          onClick={onClick}
          tabIndex={isMobileMenuOpen ? -1 : 0}
        >
          {text}
        </button>
      )}
    </>
  );
};

export default UnderlineLink;
