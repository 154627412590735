import { StyleSheet } from 'aphrodite';
import { theme } from '../../../../styles/theme';

const positionCardStyles = StyleSheet.create({
  title: {
    fontSize: 'calc(1.3vw + 1.3vh)',
    margin: `${theme.size.large} 0`,
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      margin: `${theme.size.small} 0`,
      fontSize: 'calc(1.6vw + 1.6vh)',
    },
  },
});

export default positionCardStyles;
