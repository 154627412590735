import { StyleSheet } from 'aphrodite';
import { media } from '../../../util/media';

const contactDetailsStyles = StyleSheet.create({
  background: {
    background: `url(${media.images.background.pattern.src})`,
    backgroundSize: '30%',
  },
});

export default contactDetailsStyles;
