import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

const footerStyles = StyleSheet.create({
  background: {
    backgroundColor: theme.colour.purple,
  },
  container: {
    padding: '4vw 8vw',
  },
  containerMedium: {
    padding: '4vw',
  },
  containerExtraLarge: {
    padding: '4vw 12vw',
  },
  logoContainerExtraLarge: {
    width: 'calc(6vw + 6vh)',
  },
  logoContainer: {
    width: 'calc(8vw + 8vh)',
  },
  logoContainerMedium: {
    width: 'calc(6vw + 6vh)',
  },
  dividerLine: {
    gap: theme.size.xsmall,
  },
  text: {
    fontSize: '14px',
  },
  appendumText: {
    fontSize: '12px',
  },
  appendumDividerLine: {
    gap: theme.size.xxxsmall,
  },
  heading: {
    fontSize: '20px',
    marginTop: 0,
    marginBottom: theme.size.xxxsmall,
  },
  subHeading: {
    fontSize: '18px',
    margin: 0,
    marginTop: theme.size.medium,
  },
  socialIconHover: {
    transition: 'opacity 0.2s ease-in-out',
    ':hover': {
      opacity: 0.6,
    },
  },
  columnOneContainer: {
    padding: '0 1vw 0 0',
  },
  columnTwoContainer: {
    padding: '0 1vw',
  },
  columnThreeContainer: {
    padding: '0 0 0 1vw',
  },
});

export default footerStyles;
