import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const toggleStyles = StyleSheet.create({
  toggleContainer: {
    backgroundColor: theme.colour.purple,
    borderRadius: theme.size.medium,
    width: '64px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    border: 'none',
  },
  toggleContainerEnabled: {
    backgroundColor: theme.colour.purple,
  },
  toggleContainerDisabled: {
    backgroundColor: theme.colour.grey[20],
  },
  toggleHandle: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    backgroundColor: theme.colour.white,
  },
  toggleHandleEnabled: {
    transform: 'translateX(32px)',
  },
  transition: {
    transition: 'all 0.3s ease-in-out',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
});

export default toggleStyles;
