import { StyleSheet } from 'aphrodite';

const collapsibleCardStyles = StyleSheet.create({
  contentContainer: {
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-out',
    paddingTop: 0,
  },
});

export default collapsibleCardStyles;
