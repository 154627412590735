import React from 'react';
import { css } from 'aphrodite';
import { useScreenSizeContext } from '../../util/screensize.context';
import headerStyles from './header.styles';
import { commonStyles } from '../../styles/common.styles';
import { MediaItem } from '../../types/general';
import generalUtil from '../../util/general';

export type HeaderProps = {
  image: MediaItem | MediaItem[];
  title?: string;
  subtitle?: string;
  titleDesc?: string;
  subImage?: MediaItem;
  alwaysFullScreen?: boolean;
  backgroundPosition?: string;
  children?: React.ReactNode;
};

const Header = ({
  image,
  title,
  subtitle,
  titleDesc,
  subImage,
  alwaysFullScreen,
  backgroundPosition = 'center',
  children,
}: HeaderProps) => {
  const { isScreenXSmall } = useScreenSizeContext();

  const bgImage = React.useMemo(
    () =>
      Array.isArray(image) ? generalUtil.getRandomEntryFromArray(image) : image,
    [image],
  );

  return (
    <div
      className={css(
        headerStyles.container,
        isScreenXSmall && !alwaysFullScreen
          ? headerStyles.halfScreenHeight
          : headerStyles.fullScreenHeight,
      )}
      style={{
        backgroundImage: `url(${bgImage.src})`,
        backgroundPosition,
      }}
      aria-label={bgImage.alt}
    >
      {children && <>{children}</>}
      {!children && (
        <div className={css(headerStyles.contentContainer)}>
          <div
            className={css(
              commonStyles.flex,
              commonStyles.spaceBetween,
              headerStyles.contentSubContainer,
            )}
          >
            <div
              className={css(
                commonStyles.flex,
                commonStyles.flexColumn,
                commonStyles.justifyEnd,
              )}
            >
              {title && (
                <h1 className={css(commonStyles.noMargin, headerStyles.title)}>
                  {title}
                </h1>
              )}
              {titleDesc && (
                <h3 className={css(headerStyles.titleDesc)}>{titleDesc}</h3>
              )}
              {subtitle && (
                <h2
                  className={css(commonStyles.noMargin, headerStyles.subtitle)}
                >
                  {subtitle}
                </h2>
              )}
            </div>
            <div className={css(commonStyles.flex, commonStyles.justifyEnd)}>
              {subImage && (
                <img
                  src={subImage.src}
                  width={'60%'}
                  alt={subImage.alt}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
