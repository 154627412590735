import React from 'react';
import { theme } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { css } from 'aphrodite';
import { commonStyles } from '../../../styles/common.styles';
import { useSessionContext } from '../../../util/session.context';
import { mobileNavStyles } from './mobile-nav.styles';
import { Link, useLocation } from 'react-router-dom';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { media } from '../../../util/media';
import { Page } from '../../../types/navigation';
import Button from '../../button/button';

type MobileNavItemProps = {
  title: string;
  link: string;
  subtitle?: string;
};

const MobileNavItem = ({ title, link, subtitle }: MobileNavItemProps) => {
  const { isMobileMenuOpen: isMenuOpen, closeMobileMenu: closeMenu } =
    useSessionContext();

  const onItemClick = () => {
    closeMenu();
  };

  const { isScreenLargeOrMore } = useScreenSizeContext();

  React.useEffect(() => {
    if (isMenuOpen && isScreenLargeOrMore) {
      closeMenu();
    }
  }, [isMenuOpen, isScreenLargeOrMore]);

  return (
    <Link to={link} className={css(commonStyles.nativeReset)} tabIndex={-1}>
      <button
        className={css(commonStyles.nativeReset, commonStyles.fullWidth)}
        title={title}
        onClick={onItemClick}
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.spaceBetween,
            commonStyles.bold,
            mobileNavStyles.navItem,
          )}
        >
          <div>{title}</div>
          <FontAwesomeIcon icon={faArrowRight} color={theme.colour.white} />
        </div>
        {subtitle && (
          <div className={css(mobileNavStyles.navItemSubtitle)}>{subtitle}</div>
        )}
      </button>
    </Link>
  );
};

const MobileNav = () => {
  const { isMobileMenuOpen: isMenuOpen, closeMobileMenu: closeMenu } =
    useSessionContext();

  const location = useLocation();

  // navigation is already handled if used in <Link />
  const handleHomeClick = () => {
    if (location.pathname === Page.Home) {
      window.scrollTo({ top: 0 });
    }
    closeMenu();
  };

  return (
    <div
      className={css(
        mobileNavStyles.navBase,
        isMenuOpen ? mobileNavStyles.navVisible : mobileNavStyles.navHidden,
      )}
    >
      <div
        className={css(
          commonStyles.flex,
          commonStyles.centreVertical,
          commonStyles.spaceBetween,
          mobileNavStyles.navControls,
        )}
      >
        <Link to={Page.Home} onClick={handleHomeClick}>
          <img
            src={media.brand.global.fleurDeLis.white.src}
            width={'52px'}
            alt={media.brand.global.fleurDeLis.white.alt}
          />
        </Link>
        <button
          className={css(commonStyles.nativeReset)}
          onClick={closeMenu}
          title='Close'
        >
          <FontAwesomeIcon
            icon={faXmark}
            color={theme.colour.white}
            size='2xl'
          />
        </button>
      </div>
      <div>
        <h3 className={css(mobileNavStyles.navSectionHeading)}>Our Sections</h3>
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapXLarge,
            mobileNavStyles.navItemContainer,
          )}
        >
          <div
            className={css(
              commonStyles.flex,
              commonStyles.flexColumn,
              commonStyles.gapSmall,
            )}
          >
            <MobileNavItem
              title='Squirrels'
              subtitle='Aged 4-6'
              link='/squirrels'
            />
            <MobileNavItem
              title='Beavers'
              subtitle='Aged 6-8'
              link='/beavers'
            />
            <MobileNavItem title='Cubs' subtitle='Aged 8-10½' link='/cubs' />
            <MobileNavItem
              title='Scouts'
              subtitle='Aged 10½-14'
              link='/scouts'
            />
            <MobileNavItem
              title='Explorers'
              subtitle='Aged 14-18'
              link='/explorers'
            />
            <MobileNavItem
              title='Network'
              subtitle='Aged 18-25'
              link='/network'
            />
          </div>
          <div
            className={css(
              commonStyles.gapXSmall,
              commonStyles.flex,
              commonStyles.flexColumn,
            )}
          >
            <MobileNavItem title='Volunteer' link='/volunteer' />
            <MobileNavItem title='Contact Us' link='/contact' />
          </div>
        </div>
        <div className={css(commonStyles.marginTopXLarge)}>
          <Button
            label='Join Us'
            variant='secondary'
            fontSize='28px'
            bold
            borderOnHover
            focusable
            iconPosition='right'
            icon={faArrowRight}
            link={Page.Join}
            onClick={() => closeMenu()}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
