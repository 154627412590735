import React from 'react';
import { useLocation } from 'react-router-dom';

const RouterScroll = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scroll({ top: 0, left: 0 });
  }, [location]);

  return null;
};

export default RouterScroll;
