import { css } from 'aphrodite';
import React from 'react';
import { buttonStyles, getButtonThemeStyles } from './button.styles';
import { commonStyles } from '../../styles/common.styles';
import { CustomIcon } from '../custom-icon/custom-icon';
import { CustomIconName } from '../custom-icon/custom-icons';
import { theme } from '../../styles/theme';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export type ButtonVariant =
  | 'primary'
  | 'primary-red'
  | 'primary-blue'
  | 'primary-green'
  | 'primary-teal'
  | 'primary-navy'
  | 'primary-black'
  | 'secondary'
  | 'secondary-black'
  | 'secondary-blue';

export type ButtonProps = {
  label: string;
  variant?: ButtonVariant;
  fontSize?: string;
  bold?: boolean;
  icon?: CustomIconName | IconDefinition;
  iconPosition?: 'left' | 'right';
  borderOnHover?: boolean;
  link?: string;
  linkInNewTab?: boolean;
  focusable?: boolean;
  dropShadow?: boolean;
  fullWidth?: boolean;
  onHover?: () => void;
  onUnhover?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
};

type ButtonIconProps = {
  icon: CustomIconName | IconDefinition;
  fontSize?: string;
};

const ButtonIcon = ({ icon, fontSize }: ButtonIconProps) => {
  return (
    <>
      {typeof icon === 'string' ? (
        <CustomIcon
          icon={icon}
          width={fontSize ?? theme.size.small}
          style={{ transition: 'all 0.2s ease-in-out' }}
        />
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
    </>
  );
};

const ButtonContent = ({
  label,
  fontSize,
  bold,
  icon,
  iconPosition = 'left',
  variant = 'primary',
  borderOnHover,
  focusable = true,
  dropShadow,
  fullWidth,
  onHover,
  onUnhover,
  onFocus,
  onBlur,
  onClick,
}: ButtonProps) => {
  const [isHover, setIsHover] = React.useState(false);

  const buttonTheme = React.useMemo(
    () => getButtonThemeStyles(variant),
    [variant],
  );

  return (
    <button
      className={css(
        commonStyles.nativeReset,
        buttonStyles.base,
        fullWidth ? commonStyles.fullWidth : commonStyles.fitContentWidth,
        isHover ? buttonTheme.hover : buttonTheme.button,
        isHover && borderOnHover && buttonTheme.hoverBorder,
        dropShadow && commonStyles.shadow,
      )}
      onMouseOver={() => {
        setIsHover(true);
        if (onHover) {
          onHover();
        }
      }}
      onFocus={() => {
        setIsHover(true);
        if (onFocus) {
          onFocus();
        }
      }}
      onMouseLeave={() => {
        setIsHover(false);
        if (onUnhover) {
          onUnhover();
        }
      }}
      onBlur={() => {
        setIsHover(false);
        if (onBlur) {
          onBlur();
        }
      }}
      onClick={onClick}
      tabIndex={focusable ? 0 : -1}
    >
      <div
        style={{ fontSize }}
        className={css(
          buttonStyles.contentContainerBase,
          icon
            ? buttonStyles.contentContainerWithIcon
            : buttonStyles.contentContainerWithoutIcon,
          commonStyles.flex,
          fullWidth && commonStyles.spaceBetween,
          icon && (isHover ? buttonTheme.iconHover : buttonTheme.icon),
          bold && commonStyles.bold,
        )}
      >
        {icon && iconPosition === 'left' && (
          <ButtonIcon icon={icon} fontSize={fontSize} />
        )}
        {label}
        {icon && iconPosition === 'right' && (
          <ButtonIcon icon={icon} fontSize={fontSize} />
        )}
      </div>
    </button>
  );
};

const Button = ({
  label,
  fontSize,
  bold,
  icon,
  iconPosition,
  variant,
  borderOnHover,
  link,
  linkInNewTab,
  focusable = true,
  dropShadow,
  fullWidth,
  onHover,
  onUnhover,
  onFocus,
  onBlur,
  onClick,
}: ButtonProps) => {
  return link ? (
    <Link to={link} target={linkInNewTab ? '_blank' : '_self'} tabIndex={-1}>
      <ButtonContent
        label={label}
        fontSize={fontSize}
        bold={bold}
        icon={icon}
        iconPosition={iconPosition}
        variant={variant}
        borderOnHover={borderOnHover}
        focusable={focusable}
        dropShadow={dropShadow}
        fullWidth={fullWidth}
        onHover={onHover}
        onUnhover={onUnhover}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      />
    </Link>
  ) : (
    <ButtonContent
      label={label}
      fontSize={fontSize}
      bold={bold}
      icon={icon}
      iconPosition={iconPosition}
      variant={variant}
      borderOnHover={borderOnHover}
      focusable={focusable}
      dropShadow={dropShadow}
      fullWidth={fullWidth}
      onHover={onHover}
      onUnhover={onUnhover}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
    />
  );
};

export default Button;
