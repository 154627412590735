import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../../styles/common.styles';
import { media } from '../../../util/media';
import contactIntroductionStyles from './contact-introduction.styles';
import { useScreenSizeContext } from '../../../util/screensize.context';

const ContactIntroduction = () => {
  const { isScreenMediumOrLess } = useScreenSizeContext();
  return (
    <div
      className={css(
        commonStyles.screenMargin,
        commonStyles.flex,
        commonStyles.centreHorizontal,
      )}
    >
      <div className={css(commonStyles.maxScreenWidth)}>
        <div className={css(commonStyles.flex)}>
          <div style={{ width: isScreenMediumOrLess ? '100%' : '70%' }}>
            <h2
              className={css(
                commonStyles.pageSectionHeading,
                commonStyles.noMargin,
              )}
            >
              Get In Touch
            </h2>
            <p>
              We&apos;re here to help you embark on you or your child&apos;s
              Scouting adventure. Whether you have questions, need more
              information, or just want to connect with us, we&apos;re more than
              happy to help.
            </p>
            <p className={css(commonStyles.bold)}>
              Reach out to the relevant contact below, for both general and
              specific enquiries.
            </p>
          </div>
          {!isScreenMediumOrLess && (
            <div
              className={css(
                commonStyles.flex,
                commonStyles.centreHorizontal,
                contactIntroductionStyles.imageContainer,
              )}
            >
              <img
                className={css(contactIntroductionStyles.image)}
                src={media.images.contact.introduction.src}
                alt={media.images.contact.introduction.alt}
                width={'100%'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactIntroduction;
