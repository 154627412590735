import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

const baseSectionCardStyles = StyleSheet.create({
  container: {
    borderRadius: theme.size.small,
  },
  logoImg: {
    objectFit: 'contain',
    objectPosition: '100%',
  },
  logoContainer: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
  },
  logoContainerSmall: {
    height: '6vw',
  },
  logoContainerLarge: {
    height: '3vw',
  },
});

export default baseSectionCardStyles;
