import { ReactComponent as ArrowCircleRight } from './svg/arrow-circle-right.svg';
import React, { CSSProperties } from 'react';
import { CustomIconName } from './custom-icons';

export type CustomIconProps = {
  icon: CustomIconName;
  stroke?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
};

export const CustomIcon = ({
  icon,
  stroke,
  width,
  height,
  style,
}: CustomIconProps): JSX.Element => {
  switch (icon) {
    case CustomIconName.ArrowCircleRight: {
      return (
        <ArrowCircleRight
          stroke={stroke}
          width={width}
          height={height}
          style={style}
        />
      );
    }
  }
};
