import { ContactType, ContactData } from "../../types/general";
import { media } from "../media";

const contactData = new Map<ContactType, ContactData>([
  [
    'Squirrels',
    {
      name: 'Hope Nolan',
      img: media.images.people.hopeNolan,
      email: 'squirrels@2ndrugeleyscoutgroup.com',
      tel: '07468339383',
      messenger: true,
    },
  ],
  [
    'Beavers',
    {
      name: 'Nicola Clay',
      img: media.images.people.placeholder,
      email: 'beavers@2ndrugeleyscoutgroup.com',
      messenger: false,
    },
  ],
  [
    'Cubs',
    {
      name: 'Martin Millson',
      img: media.images.people.martinMillson,
      email: 'cubs@2ndrugeleyscoutgroup.com',
      messenger: false,
    },
  ],
  [
    'Scouts',
    {
      name: 'Archie Billingsley',
      img: media.images.people.archieBillingsley,
      email: 'scouts@2ndrugeleyscoutgroup.com',
      messenger: false,
    },
  ],
  [
    'Explorers',
    {
      name: 'Matt Taylor',
      img: media.images.people.placeholder,
      email: 'explorers@2ndrugeleyscoutgroup.com',
      tel: '07535294221',
      messenger: false,
    },
  ],
  [
    'Network',
    {
      name: 'Joanne Carter',
      title: 'District Scout Network Commissioner',
      img: media.images.people.joanneCarter,
      email: 'network@2ndrugeleyscoutgroup.com',
      tel: '07866806081',
      messenger: true,
    },
  ],
  [
    'GSL',
    {
      name: 'Joanne Carter',
      title: 'Group Scout Leader',
      img: media.images.people.joanneCarter,
      email: 'gsl@2ndrugeleyscoutgroup.com',
      tel: '07866806081',
      messenger: true,
    },
  ],
  [
    'AGSL',
    {
      name: 'Dylan Priest',
      title: 'Asst. Group Scout Leader',
      img: media.images.people.placeholder,
      email: 'agsl@2ndrugeleyscoutgroup.com',
      tel: '07531103490',
      messenger: false,
    },
  ],
  [
    'Chairperson',
    {
      name: 'Joe McGrath',
      title: 'Chairperson',
      img: media.images.people.placeholder,
      email: 'chairperson@2ndrugeleyscoutgroup.com',
      messenger: false,
    },
  ],
  [
    'Publicity',
    {
      name: 'Benjamin Griffiths',
      title: 'Publicity Co-Ordinator',
      img: media.images.people.benjaminGriffiths,
      email: 'enquiries@2ndrugeleyscoutgroup.com',
      messenger: true,
    },
  ],
]);

export const getContactData = (contactType: ContactType) => {
  return contactData.get(contactType);
}