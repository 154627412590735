import React from 'react';
import { theme } from '../styles/theme';

interface ScreenSizeContextType {
  screenWidth: number;
  isScreenXLarge: boolean;
  isScreenLarge: boolean;
  isScreenLargeOrMore: boolean;
  isScreenMedium: boolean;
  isScreenMediumOrLess: boolean;
  isScreenSmall: boolean;
  isScreenSmallOrLess: boolean;
  isScreenXSmall: boolean;
  screenScrollValue: number;
  isScreenScrolled: boolean;
}

const ScreenSizeContext = React.createContext<
  ScreenSizeContextType | undefined
>(undefined);

export const useScreenSizeContext = () => {
  const context = React.useContext(ScreenSizeContext);
  if (!context) {
    throw new Error('useScreenSize must be used within a ScreenSizeProvider');
  }
  return context;
};

const determineIfScrolled = () => {
  return window.scrollY >= 50;
};

export const ScreenSizeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [isScrolled, setIsScrolled] = React.useState(determineIfScrolled());

  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    const handleScroll = () => setIsScrolled(determineIfScrolled());

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isScreenXLarge = screenWidth >= theme.breakpoints.xlarge;

  const isScreenLarge =
    screenWidth >= theme.breakpoints.large &&
    screenWidth < theme.breakpoints.xlarge;
  const isScreenLargeOrMore = screenWidth >= theme.breakpoints.large;

  const isScreenMediumOrLess = screenWidth < theme.breakpoints.large;
  const isScreenMedium =
    screenWidth >= theme.breakpoints.medium &&
    screenWidth < theme.breakpoints.large;

  const isScreenSmall =
    screenWidth >= theme.breakpoints.small &&
    screenWidth < theme.breakpoints.medium;
  const isScreenSmallOrLess = screenWidth < theme.breakpoints.medium;

  const isScreenXSmall = screenWidth < theme.breakpoints.small;

  return (
    <ScreenSizeContext.Provider
      value={{
        screenWidth,
        isScreenXLarge,
        isScreenLarge,
        isScreenLargeOrMore,
        isScreenMedium,
        isScreenMediumOrLess,
        isScreenSmall,
        isScreenSmallOrLess,
        isScreenXSmall,
        screenScrollValue: window.scrollY,
        isScreenScrolled: isScrolled,
      }}
    >
      {children}
    </ScreenSizeContext.Provider>
  );
};
