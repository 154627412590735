import { StyleSheet } from 'aphrodite';

const animatedButtonLearnMoreStyles = StyleSheet.create({
  transition: {
    transition: 'all 0.5s ease-in-out',
  },
  closed: {
    transform: 'translateX(91px)',
    width: '31px',
  },
  closedText: {
    transform: 'translateX(94px)',
    width: '31px',
  },
  open: {
    width: '122px',
  }
});

export default animatedButtonLearnMoreStyles;
