import React from 'react';
import SocialIcon from './social-icon';
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { commonStyles } from '../../../../styles/common.styles';
import { css } from 'aphrodite';

const SocialIconBar = () => {
  return (
    <div
      className={css(
        commonStyles.flex,
        commonStyles.marginTopXSmall,
        commonStyles.gapSmall,
      )}
    >
      <SocialIcon
        icon={faFacebookF}
        ariaLabel='Facebook'
        link='https://facebook.com/2ndRugeley'
      />
      <SocialIcon
        icon={faInstagram}
        ariaLabel='Instagram'
        link='https://instagram.com/2ndRugeley'
      />
      <SocialIcon
        icon={faYoutube}
        ariaLabel='YouTube'
        link='https://youtube.com/@2ndRugeley'
      />
      <SocialIcon
        icon={faTiktok}
        ariaLabel='TikTok'
        link='https://www.tiktok.com/@2ndrugeleyscoutgroup'
      />
    </div>
  );
};

export default SocialIconBar;
