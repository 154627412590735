import { StyleSheet } from 'aphrodite';
import { theme } from '../../../../styles/theme';

const contactCardStyles = StyleSheet.create({
  nameSmall: {
    fontSize: 'calc(0.9vw + 0.9vh)',

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.small}px)`]:
      {
        fontSize: 'calc(1.2vw + 1.2vh)',
      },

    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 'calc(1.4vw + 1.4vh)',
    },
  },
  nameLarge: {
    fontSize: 'calc(1.2vw + 1.2vh)',

    [`@media (max-width: ${theme.breakpoints.xlarge}px) and (min-width: ${theme.breakpoints.large}px)`]:
      {
        fontSize: 'calc(1.4vw + 1.4vh)',
      },

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.medium}px)`]:
      {
        fontSize: 'calc(1.2vw + 1.2vh)',
      },

    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 'calc(1.9vw + 1.9vh)',
    },
  },
  titleSmall: {
    fontSize: 'calc(0.7vw + 0.7vh)',

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.small}px)`]:
      {
        fontSize: 'calc(1vw + 1vh)',
      },

    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 'calc(1.2vw + 1.2vh)',
    },
  },
  titleLarge: {
    fontSize: 'calc(0.8vw + 0.8vh)',
    marginBottom: theme.size.small,

    [`@media (max-width: ${theme.breakpoints.xlarge}px) and (min-width: ${theme.breakpoints.large}px)`]:
      {
        fontSize: 'calc(1vw + 1vh)',
      },

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.medium}px)`]:
      {
        fontSize: 'calc(1vw + 1vh)',
      },

    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 'calc(1.4vw + 1.4vh)',
    },
  },
  linksContainerSmall: {
    fontSize: 'calc(1.5vw + 1.5vh)',
    gap: 'calc(1.3vw + 1.3vh)',

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.small}px)`]:
      {
        fontSize: 'calc(2.4vw + 2.4vh)',
        gap: 'calc(1.5vw + 1.5vh)',
      },

    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 'calc(2.8vw + 2.8vh)',
      gap: theme.size.large,
    },
  },
  linksContainerLarge: {
    fontSize: 'calc(1.8vw + 1.8vh)',
    gap: 'calc(1vw + 1vh)',
    marginBottom: theme.size.small,

    [`@media (min-width: ${theme.breakpoints.large}px)`]:
      {
        marginLeft: `calc(calc(1.8vw + 1.8vh) - ${theme.size.small})`,
      },

    [`@media (max-width: ${theme.breakpoints.xlarge}px) and (min-width: ${theme.breakpoints.large}px)`]:
      {
        fontSize: 'calc(2vw + 2vh)',
        gap: 'calc(1.3vw + 1.3vh)',
        marginLeft: `calc(calc(1.8vw + 1.8vh) - ${theme.size.small})`,
      },

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.medium}px)`]:
      {
        fontSize: 'calc(2.4vw + 2.4vh)',
        gap: 'calc(1.5vw + 1.5vh)',
      },

    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 'calc(3vw + 3vh)',
      gap: theme.size.medium,
    },
  },
});

export default contactCardStyles;
