import React from 'react';
import { Helmet } from 'react-helmet';
import applicationVariables from '../variables';
import { useSessionContext } from '../session.context';

const Analytics = () => {
  const { cookieConsentStatus } = useSessionContext();

  return (
    <>
      {cookieConsentStatus === true && (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${applicationVariables.gaMeasurementId}`}
          />
          <script>
            {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${applicationVariables.gaMeasurementId}');`}
          </script>
        </Helmet>
      )}
    </>
  );
};

export default Analytics;
