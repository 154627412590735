import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import Card from '../../components/card/card';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import joinStyles from './join.styles';
import Button from '../../components/button/button';
import { CustomIconName } from '../../components/custom-icon/custom-icons';
import applicationVariables from '../../util/variables';
import { useScreenSizeContext } from '../../util/screensize.context';
import { useSessionContext } from '../../util/session.context';

const JoinUs = () => {
  const [leftCardHover, setLeftCardHover] = React.useState(false);
  const [rightCardHover, setRightCardHover] = React.useState(false);

  const { isScreenSmallOrLess, screenWidth } = useScreenSizeContext();

  const { isMobileMenuOpen } = useSessionContext();

  const screenBreakpoint1 = React.useMemo(
    () => screenWidth < 1220 && screenWidth >= 900,
    [screenWidth],
  );

  const screenBreakpoint2 = React.useMemo(
    () => screenWidth < 900,
    [screenWidth],
  );

  const cardHeight = React.useMemo(() => {
    if (isScreenSmallOrLess) {
      return 'fit-content';
    } else if (screenBreakpoint1) {
      return '50vw';
    } else if (screenBreakpoint2) {
      return '60vw';
    } else {
      return '36vw';
    }
  }, [isScreenSmallOrLess, screenBreakpoint1, screenBreakpoint2]);

  return (
    <>
      <Header image={media.images.background.zipline} title='Join Us' />
      <div
        className={css(
          commonStyles.flex,
          commonStyles.gapSmall,
          isScreenSmallOrLess && commonStyles.flexColumn,
          isScreenSmallOrLess && commonStyles.centreVertical,
          joinStyles.cardsContainer,
        )}
      >
        <div
          className={css(commonStyles.smooth)}
          style={{
            width: isScreenSmallOrLess ? '100%' : '50%',
            height: cardHeight,
          }}
        >
          <div
            className={css(
              leftCardHover
                ? joinStyles.singleCardContainerHover
                : joinStyles.singleCardContainer,
              commonStyles.smooth,
            )}
          >
            <Card
              fullWidth
              fullHeight
              backgroundColor={`rgba(0,0,0,${leftCardHover ? '0.4' : '0.6'})`}
              backgroundImage={media.images.background.zipline2.src}
              backgroundBlend
              onHover={() => {
                setLeftCardHover(true);
              }}
              onFocus={() => {
                setLeftCardHover(true);
              }}
              onUnhover={() => {
                setLeftCardHover(false);
              }}
              onBlur={() => {
                setLeftCardHover(false);
              }}
            >
              <div
                className={css(
                  commonStyles.flex,
                  commonStyles.flexColumn,
                  commonStyles.centreVertical,
                  commonStyles.smooth,
                  commonStyles.gapMedium,
                  commonStyles.spaceEvenly,
                  joinStyles.cardInnerPadding,
                  leftCardHover ? joinStyles.contentHover : joinStyles.content,
                )}
              >
                <h3
                  className={css(
                    commonStyles.pageSectionHeading,
                    commonStyles.textWhite,
                    commonStyles.textAlignCentre,
                    commonStyles.noMargin,
                  )}
                >
                  Enrol
                </h3>
                <h4
                  className={css(
                    commonStyles.textWhite,
                    commonStyles.textAlignCentre,
                    commonStyles.noMargin,
                    joinStyles.subtitle,
                  )}
                >
                  Are you ready to join the adventure?
                </h4>
                <Button
                  label='Waiting List'
                  variant='secondary-black'
                  bold
                  fontSize={
                    isScreenSmallOrLess
                      ? 'calc(1.5vw + 1.5vh)'
                      : 'calc(1vw + 1vh)'
                  }
                  icon={CustomIconName.ArrowCircleRight}
                  link={applicationVariables.osmWaitListUrl}
                  linkInNewTab
                  focusable={!isMobileMenuOpen}
                />
                <p
                  className={css(
                    commonStyles.textWhite,
                    commonStyles.textAlignCentre,
                    commonStyles.noMargin,
                    joinStyles.note,
                  )}
                >
                  We currently operate a waiting list system using our Scouting
                  management system, Online Scout Manager. However, this does
                  not always mean all of our sections are full - it&apos;s our
                  way of ensuring spaces are allocated fairly. If a section is
                  full, we will let you know and you will be added to the
                  waiting list. You will then be contacted at the earliest
                  convenience when a space becomes available.
                </p>
              </div>
            </Card>
          </div>
        </div>
        <div
          className={css(commonStyles.smooth)}
          style={{
            width: isScreenSmallOrLess ? '100%' : '50%',
            height: cardHeight,
          }}
        >
          <div
            className={css(
              rightCardHover
                ? joinStyles.singleCardContainerHover
                : joinStyles.singleCardContainer,
              commonStyles.smooth,
            )}
          >
            <Card
              fullWidth
              fullHeight
              backgroundColor={`rgba(0,0,0,${rightCardHover ? '0.4' : '0.6'})`}
              backgroundImage={media.images.join.leader.src}
              backgroundBlend
              onHover={() => {
                setRightCardHover(true);
              }}
              onFocus={() => {
                setRightCardHover(true);
              }}
              onUnhover={() => {
                setRightCardHover(false);
              }}
              onBlur={() => {
                setRightCardHover(false);
              }}
            >
              <div
                className={css(
                  commonStyles.flex,
                  commonStyles.flexColumn,
                  commonStyles.centreVertical,
                  commonStyles.smooth,
                  commonStyles.gapMedium,
                  commonStyles.spaceEvenly,
                  joinStyles.cardInnerPadding,
                  rightCardHover ? joinStyles.contentHover : joinStyles.content,
                )}
              >
                <h3
                  className={css(
                    commonStyles.pageSectionHeading,
                    commonStyles.textWhite,
                    commonStyles.textAlignCentre,
                    commonStyles.noMargin,
                  )}
                >
                  Become a Leader
                </h3>
                <h4
                  className={css(
                    commonStyles.textWhite,
                    commonStyles.textAlignCentre,
                    commonStyles.noMargin,
                    joinStyles.subtitle,
                  )}
                >
                  Share your skills, gain new ones, and inspire a generation.
                </h4>
                <Button
                  label='Register'
                  variant='secondary-black'
                  bold
                  fontSize={
                    isScreenSmallOrLess
                      ? 'calc(1.5vw + 1.5vh)'
                      : 'calc(1vw + 1vh)'
                  }
                  icon={CustomIconName.ArrowCircleRight}
                  link={applicationVariables.osmWaitListUrl}
                  linkInNewTab
                  focusable={!isMobileMenuOpen}
                />
                <p
                  className={css(
                    commonStyles.textWhite,
                    commonStyles.textAlignCentre,
                    commonStyles.noMargin,
                    joinStyles.note,
                  )}
                >
                  Note: The form asks for &quot;Child&apos;s Details&quot;.
                  Don&apos;t worry about this - this is just because we use the
                  same form for all join requests, including young members.
                  You&apos;ll need to input your own details in this section,
                  and repeat them in the &quot;Your Details&quot; section
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
