import { StyleSheet } from 'aphrodite';
import { theme } from '../../../../styles/theme';

const sectionIntroductionStyles = StyleSheet.create({
  contentContainer: {
    maxWidth: '1440px',
    alignSelf: 'center',

    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      gap: theme.size.large,
      flexDirection: 'column',
    },

    [`@media (min-width: ${theme.breakpoints.medium}px)`]: {
      gap: theme.size.xlarge,
    },
  },
  image: {
    objectFit: 'contain',
    objectPosition: '50% 0%',
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      width: '80%',
    },

    [`@media (max-width: ${theme.breakpoints.large}px) and (min-width: ${theme.breakpoints.medium}px)`]: {
      width: '350px',
    },

    [`@media (min-width: ${theme.breakpoints.large}px)`]: {
        width: '450px',
      },
  },
});

export default sectionIntroductionStyles;
