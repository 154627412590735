import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import generalUtil from '../../util/general';
import SectionIntroduction from './shared/introduction/section-introduction';
import SectionDetails from './shared/details/section-details';
import { SectionDetailData } from '../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import SectionDetailsBadgePlacement from './shared/details/badge-placement/section-details-badge-placement';
import SectionDetailsPromise from './shared/details/promise/section-details-promise';
import SectionDetailsSafeguarding from './shared/details/safeguarding/section-details-safeguarding';
import SectionDetailsMoreInformation from './shared/details/more-information/section-details-more-information';
import SectionDetailsWhereWhen from './shared/details/where-when/section-details-where-when';
import SectionDetailsMovingOn from './shared/details/moving-on/section-details-moving-on';
import SectionContact from './shared/contact/section-contact';

const Squirrels = () => {
  const [openCardIndexes, setOpenCardIndexes] = React.useState<number[]>([]);

  const detailData: SectionDetailData = [
    {
      question: 'What do Squirrels do?',
      answer: `Each badge is packed with wow and wonder, perfect for anyone who's ever asked "Why?" (or "Why not?"). You'll tell stories and play games. Get active and explore the outdoors. Become a brilliant builder and a super chef. There's something for everyone - and that means that there's something for you.`,
    },
    {
      question: 'Who can join Squirrels?',
      answer: `Anyone between the ages of 4 and 6! Inclusion is at the heart of our Squirrels story. Here at Scouts, we're open to people of all genders, backgrounds, and faiths (including people of no faith). We're committed to reaching out to new and different families and communities and including people who have never tried Scouts before.`,
    },
    {
      question: 'Where & when do Squirrels meet?',
      answer: (
        <SectionDetailsWhereWhen
          fullSectionName='Our Squirrel Drey'
          dateTime='Thursdays, from 17:30 - 18:30'
          isVisible
        />
      ),
    },
    {
      question: 'Do Squirrels have a uniform?',
      answer: `Yes - just like the rest of our sections! Squirrels wear just a single red jumper or polo shirt. However, we don't want parents to worry about uniform just yet; once your child is settled into Squirrels, talk to the section leader for more information.`,
    },
    {
      question: 'Where do badges go?',
      answer: (
        <SectionDetailsBadgePlacement
          section='Squirrels'
          isVisible={openCardIndexes.includes(1)}
        />
      ),
    },
    {
      question: 'What is an investiture?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            Investiture is a special time where someone makes their Scout
            Promise, and becomes a member of both 2nd Rugeley, and the worldwide
            Scout family. Some people call these Promise ceremonies and
            it&apos;s often known as being &quot;invested&quot;. Parents/carers
            are often invited to watch the special occasion.
          </p>
          <p>
            Traditionally, people will make the Scout sign while they&apos;re
            making their Promise and the person leading the ceremony will often
            shake their hand afterwards, using the Scout handshake.
          </p>
          <p>
            When new Squirrels have settled in and decided they want to stay,
            they will make their Squirrel Scout Promise, which is the same as
            the Beaver Scout Promise. They&apos;re welcomed to Squirrels, and
            become a member of both their Drey (the name for a Squirrel group)
            and the worldwide Scout family.
          </p>
          <p>
            They&apos;ll get their Membership Award. This is a purple badge with
            a picture of the fleur-de-lis - an international symbol that&apos;s
            worn by Scouts all over the world. They will also get some other
            local badges too, to show where 2nd Rugeley is located.
          </p>
          <p className={css(commonStyles.noMarginBottom)}>
            They&apos;ll also get their scarf, which they can proudly wear, to
            show which Scout Group their Squirrel Drey is part of.
          </p>
        </>
      ),
    },
    {
      question: 'What is the Squirrel Promise?',
      answer: (
        <SectionDetailsPromise
          section='Squirrels'
          introduction='The Squirrel Scout promise will follow the same promise as the Beaver Scout promise. Squirrels will choose the promise that best suits them. Below are variations of the Squirrel Scout promise.'
          data={[
            {
              variant: 'Atheist or of no faith background',
              promise:
                'I promise to do my best to be kind and helpful and to love our world.',
            },
            {
              variant: 'Buddhist',
              promise:
                'I promise to do my best to be kind and helpful and to act with love towards everyone.',
            },
            {
              variant: 'Christian',
              promise:
                'I promise to do my best to be kind and helpful and to love the world.',
            },
            {
              variant: 'Hindu',
              promise:
                'I promise to do my best to be kind and helpful and to love the world.',
            },
            {
              variant: 'Humanist',
              promise:
                'I promise to do my best to be kind and helpful and to love our world.',
            },
            {
              variant: 'Jewish',
              promise:
                'I promise to do my best to be kind and helpful and to love God.',
            },
            {
              variant: 'Muslim',
              promise:
                'I promise to do my best to be kind and helpful and to love Allah.',
            },
            {
              variant: 'Sikh',
              promise:
                'I promise to do my best and to be kind and helpful and to love Waheguru.',
            },
          ]}
        />
      ),
    },
    {
      question: `I'm concerned about safety/safeguarding`,
      answer: (
        <SectionDetailsSafeguarding isVisible={openCardIndexes.includes(4)}>
          <p>
            Qualified Leaders will host a safe and secure programme aimed
            specifically at 4 and 5 year olds.
          </p>
          <ul>
            <li>
              For every 6 Squirrels taking part in indoor activities, there will
              be at least one adult, plus a leader in charge.
            </li>
            <li>
              Away from the safety of the usual meeting place, for every 4
              Squirrels, there will be at least one adult, plus a leader in
              charge.
            </li>
            <li>
              A minimum of 3 adults regardless of the number of Squirrels
              attending or the activity they are participating in.
            </li>
          </ul>
        </SectionDetailsSafeguarding>
      ),
    },
    {
      question: 'What happens after Squirrels?',
      answer: (
        <SectionDetailsMovingOn
          section='Squirrels'
          isVisible={openCardIndexes.includes(5)}
        />
      ),
    },
    {
      question: `I'd like more information`,
      answer: (
        <SectionDetailsMoreInformation
          section='Squirrels'
          isVisible={openCardIndexes.includes(6)}
        />
      ),
    },
  ];

  const bgImage = React.useMemo(
    () =>
      generalUtil.getRandomEntryFromArray(
        media.images.sections.squirrels.header,
      ),
    [],
  );

  return (
    <>
      <Header
        image={bgImage}
        title='Squirrels'
        subtitle='Ages 4 - 6'
        backgroundPosition={bgImage.pos}
      />
      <SectionIntroduction
        heading='Every week, Squirrels will gather to grow and learn their way through lots of different games and activities'
        image={media.images.sections.squirrels.introduction}
      >
        <>
          <p>
            Squirrels can achieve anything they set their minds to - and they
            have lots of fun along the way.
          </p>
          <p>
            The one hour sessions are a brilliant opportunity for young people
            to take their first step into being part of Scouting, and
            they&apos;ll help everyone get ready for the adventure of school
            too.
          </p>
          <p>
            Sessions will be packed with outdoor activities, fun, games, badges,
            laughter, and so much more to help our youngest members learn the
            skills they need for life.
          </p>
          <p>
            Sound like fun? That&apos;s because it is. All that&apos;s missing
            is you.
          </p>
        </>
      </SectionIntroduction>
      <SectionDetails
        data={detailData}
        formation='staggered'
        onCardChange={setOpenCardIndexes}
      />
      <SectionContact section='Squirrels' />
    </>
  );
};

export default Squirrels;
