import React from 'react';
import BaseSectionCard from '../base/base-section-card';
import { css } from 'aphrodite';
import { commonStyles } from '../../../styles/common.styles';
import { SectionStyle } from '../../../types/general';
import { useScreenSizeContext } from '../../../util/screensize.context';
import AnimatedButtonLearnMore from '../../animated-button/learn-more/animated-button-learn-more';
import sectionCardStyles from './section-card.styles';
import { Page } from '../../../types/navigation';

type SectionCardVariantData = {
  sectionName: SectionStyle;
  lowerAge: number;
  upperAge: number;
  pageLink: string;
};

const sectionCardVariants: SectionCardVariantData[] = [
  {
    sectionName: 'Squirrels',
    lowerAge: 4,
    upperAge: 6,
    pageLink: Page.Squirrels,
  },
  {
    sectionName: 'Beavers',
    lowerAge: 6,
    upperAge: 8,
    pageLink: Page.Beavers,
  },
  {
    sectionName: 'Cubs',
    lowerAge: 8,
    upperAge: 10.5,
    pageLink: Page.Cubs,
  },
  {
    sectionName: 'Scouts',
    lowerAge: 10.5,
    upperAge: 14,
    pageLink: Page.Scouts,
  },
  {
    sectionName: 'Explorers',
    lowerAge: 14,
    upperAge: 18,
    pageLink: Page.Explorers,
  },
  {
    sectionName: 'Network',
    lowerAge: 18,
    upperAge: 25,
    pageLink: Page.Network,
  },
];

const getSectionCardData = (section: SectionStyle): SectionCardVariantData => {
  return sectionCardVariants.filter((data) => data.sectionName === section)[0];
};

const getAgeRange = (data: SectionCardVariantData) => {
  const convertDecimalAge = (age: number): string => {
    return age % 1 !== 0 ? `${Math.floor(age)}½` : `${age}`;
  };

  return (
    <>
      {convertDecimalAge(data.lowerAge)}
      <span className={css(sectionCardStyles.ageRangeSpace)}>&nbsp;</span>
      {'-'}
      <span className={css(sectionCardStyles.ageRangeSpace)}>&nbsp;</span>
      {convertDecimalAge(data.upperAge)}
    </>
  );
};

export type SectionCardProps = {
  variant: SectionStyle;
};

const SectionCard = ({ variant }: SectionCardProps) => {
  const data = React.useMemo(() => getSectionCardData(variant), [variant]);

  const [isHover, setIsHover] = React.useState(false);

  const { isScreenXSmall, isScreenSmall, isScreenXLarge } =
    useScreenSizeContext();

  return (
    <BaseSectionCard
      variant={variant}
      width={
        isScreenXLarge
          ? '360px'
          : isScreenXSmall
            ? '60vw'
            : isScreenSmall
              ? '32vw'
              : '24vw'
      }
      onHover={() => setIsHover(true)}
      onUnhover={() => setIsHover(false)}
      link={data.pageLink}
    >
      <div
        className={css(commonStyles.relative)}
        style={{ position: 'relative' }}
      >
        <div className={css(sectionCardStyles.learnMoreContainer)}>
          <AnimatedButtonLearnMore animate={isHover} />
        </div>
      </div>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          commonStyles.textWhite,
        )}
      >
        <div
          style={{
            fontSize: isScreenXLarge
              ? '28px'
              : isScreenXSmall
                ? '4vw'
                : isScreenSmall
                  ? '3.5vw'
                  : '1.8vw',
            fontWeight: 700,
          }}
        >
          Aged
        </div>
        <div
          style={{
            fontSize: isScreenXLarge
              ? '64px'
              : isScreenXSmall
                ? '12vw'
                : isScreenSmall
                  ? '6vw'
                  : '4.8vw',
            fontWeight: 900,
            marginTop: isScreenXLarge ? '-8px' : '-0.5vw',
          }}
        >
          {getAgeRange(data)}
        </div>
      </div>
    </BaseSectionCard>
  );
};

export default SectionCard;
