import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import ContactIntroduction from './introduction/contact-introduction';
import ContactDetails from './details/contact-details';

const Contact = () => {
  return (
    <>
      <Header
        image={media.images.background.campfire}
        title='Contact Us'
      />
      <ContactIntroduction />
      <ContactDetails />
    </>
  );
};

export default Contact;
