import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import generalUtil from '../../util/general';
import SectionIntroduction from './shared/introduction/section-introduction';
import SectionDetails from './shared/details/section-details';
import { SectionDetailData } from '../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import SectionDetailsBadgePlacement from './shared/details/badge-placement/section-details-badge-placement';
import SectionDetailsMoreInformation from './shared/details/more-information/section-details-more-information';
import SectionDetailsMovingOn from './shared/details/moving-on/section-details-moving-on';
import SectionContact from './shared/contact/section-contact';
import Button from '../../components/button/button';
import { CustomIconName } from '../../components/custom-icon/custom-icons';
import { useScreenSizeContext } from '../../util/screensize.context';
import applicationVariables from '../../util/variables';
import { useSessionContext } from '../../util/session.context';

const Network = () => {
  const [openCardIndexes, setOpenCardIndexes] = React.useState<number[]>([]);

  const { isScreenXSmall } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();

  const detailData: SectionDetailData = [
    {
      question: 'What do Network Members do?',
      answer: (
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapMedium,
          )}
        >
          <div>
            <p className={css(commonStyles.noMarginTop)}>
              Whether you&apos;re organising a local meet-up or a once in a
              lifetime trip to the Amazon, it&apos;s all about working together
              to build new skills, seek out new experiences and make a
              difference in the world.
            </p>
            <p>
              Network groups are flexibly run and self-led. Members can create
              profiles on Social Media using the platform to meet others with
              similar goals and share their progress, members can also work
              towards a number of Top Awards.
            </p>
            <p>
              Members manage their own journey, organising their own projects
              with the support of a District Scout Network Commissioner and
              Programme Coordinator (if one is in place). Throughout their
              journey, they might also interact with Assistant County
              Commissioners (Network) - who assist with Network activity
              throughout the wider County - and the UK Scout Network
              Commissioner, who oversees Network activity across the whole of
              the UK.
            </p>
            <b className={css(commonStyles.text)}>Adventure</b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Network Members engage in adventurous and other activities that
              enable them to develop skills and pursue existing interests, as
              well as inspiring new ones. Members then use these evolved skills
              to make a positive difference in both local and Scouting
              communities.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>International</b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Network Members have the opportunity to participate in
              life-changing international activities or experiences that enable
              them to develop an understanding of, and make a positive
              contribution to, global issues, highlighting our interdependence
              as citizens of the world.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>Community</b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Network members take practical action in the service of others
              that enable them to create positive social change that is of
              benefit to a wider community, all whilst helping them to develop
              in taking action. Scout Network Members are encouraged to take
              part in projects and events that will help them to build strong
              links and appreciation for difference between Scouts and between
              others in the community, as well as supporting the other main
              themes in Network Scouting.
            </p>
          </div>
        </div>
      ),
    },
    {
      question: 'Who can join Network?',
      answer: `Anyone between the ages of 18 and 25, whether you've been part of Scouting before or not. Here at Scouts, we're open to people of all genders, backgrounds, and faiths (including people of no faith). We're committed to reaching out to new and different families and communities and including people who have never tried Scouts before.`,
    },
    {
      question: 'Where & when do Network meet?',
      answer: (
        <p className={css(commonStyles.noMargin)}>
          Network members don&apos;t necessarily meet every week. Some might
          meet a few times a year to complete a single project together. Others
          might dive into lots of different projects at once, or meet locally
          more regularly. Like any activity in Scouts, choice of venue is
          considered and takes into account suitability for the type of meeting
          and the members that will be attending that meeting.
        </p>
      ),
    },
    {
      question: 'Do Network Members have a uniform?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            Network members wear a stone shirt or blouse with an added Scout
            Network uniform badge. If they like, they can also wear the
            nationwide UK Scout Network Scarf or their local District Scout
            Network scarf (known as a necker).
          </p>
          <p>
            Uniform can either be bought from the official Scout Store or from a
            local supplier (such as Uniforms Plus). If you&apos;re not sure
            where to start, or could use a little help with uniform costs, fear
            not - speak to the District Scout Network Commissioner. The most
            important thing when you first arrive is wearing something that you
            feel natural and comfortable in.
          </p>
          <div
            className={css(
              commonStyles.flex,
              isScreenXSmall ? commonStyles.gapSmall : commonStyles.gapLarge,
              isScreenXSmall && commonStyles.flexColumn,
            )}
          >
            <Button
              label='Scout Shop'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.scoutShopUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
            <Button
              label='Uniforms Plus'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.uniformVendorUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
          </div>
        </>
      ),
    },
    {
      question: 'Where do badges go?',
      answer: (
        <SectionDetailsBadgePlacement
          section='Network'
          isVisible={openCardIndexes.includes(1)}
        />
      ),
    },
    {
      question: 'What happens after Network?',
      answer: (
        <SectionDetailsMovingOn
          section='Network'
          isVisible={openCardIndexes.includes(5)}
        />
      ),
    },
    {
      question: `I'd like more information`,
      answer: (
        <SectionDetailsMoreInformation
          section='Network'
          isVisible={openCardIndexes.includes(6)}
        />
      ),
    },
  ];

  const bgImage = React.useMemo(
    () =>
      generalUtil.getRandomEntryFromArray(media.images.sections.network.header),
    [],
  );

  return (
    <>
      <Header
        image={bgImage}
        title='Network'
        subtitle='Ages 18 - 25'
        titleDesc='Cannock Chase District Network'
        backgroundPosition={bgImage.pos}
      />
      <SectionIntroduction
        heading={`Try more. See more. Do more. At Scout Network, you'll stand on your own two feet, and make memories to last a lifetime.`}
        image={media.images.sections.network.introduction}
      >
        <>
          <p>
            Whether you&apos;re completely new to Scouts or have been with us
            since you were 5 years old, joining is a great way to meet new
            people, seek out new challenges and see the world. Because being a
            grown up shouldn&apos;t mean you have to miss out on all the fun.
          </p>
          <p>
            Scout Network connects 20,000 18-25 year olds across the UK, and
            gives them the opportunity to take part in a huge range of exciting
            projects and events centred on the themes of <b>Adventure</b>,{' '}
            <b>Community</b> and <b>International</b>.
          </p>
          <p>
            Sound like fun? That&apos;s because it is. All that&apos;s missing
            is you.
          </p>
        </>
      </SectionIntroduction>
      <SectionDetails
        data={detailData}
        formation='pyramid'
        onCardChange={setOpenCardIndexes}
      />
      <SectionContact section='Network' />
    </>
  );
};

export default Network;
