import { StyleSheet } from 'aphrodite';

const sectionCardStyles = StyleSheet.create({
  ageRangeSpace: {
    fontSize: '2vw',
  },
  learnMoreContainer: {
    position: 'absolute',
    right: 0,
    width: '40%',
  },
});

export default sectionCardStyles;
