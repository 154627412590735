import React from 'react';
import { Section } from '../../../../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../../../../styles/common.styles';

export type SectionDetailsPromiseDataItem = {
  variant: string;
  promise: string | string[];
};

export type SectionDetailsPromiseData = SectionDetailsPromiseDataItem[];

export type SectionDetailsPromiseProps = {
  section: Section;
  data: SectionDetailsPromiseData;
  introduction: string;
};

const SectionDetailsPromise = ({
  section,
  data,
  introduction,
}: SectionDetailsPromiseProps) => {
  return (
    <>
      <p className={css(commonStyles.noMarginTop)}>{introduction}</p>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          commonStyles.gapMedium,
        )}
      >
        {data.map((dataItem, index) => (
          <div
            key={`promise-${index}`}
            className={css(
              commonStyles.text,
              index === data.length - 1 && commonStyles.noMarginBottom,
            )}
          >
            <b>
              The {section.slice(0, -1)}
              {section !== 'Scouts' ? ' Scout' : ''} Promise for members who are{' '}
              {dataItem.variant}
            </b>
            <br />
            {Array.isArray(dataItem.promise)
              ? dataItem.promise.map((line, index) => (
                  <div key={`promise-line-${index}`}>{line}</div>
                ))
              : dataItem.promise}
          </div>
        ))}
      </div>
    </>
  );
};

export default SectionDetailsPromise;
