import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const homeStyles = StyleSheet.create({
  container: {
    position: 'relative',
    minHeight: '50vw',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  headingTextStack: {
    bottom: 0,
    color: theme.colour.white,
    fontSize: '3.5vw',
    zIndex: theme.layers.imageText,
    position: 'absolute',
  },
});

export default homeStyles;
