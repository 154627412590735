const generalUtil = {
    /**
     * 
     * @param min - minimum value (inclusive)
     * @param max - maximum value (exclusive)
     * @returns an integer between `min` and `max`
     */
    getRandomIntBetween: (min: number, max: number) => {
        if (max < min) {
            throw new Error(`Logical Error: minimum bound (${min}) cannot be greater than maximum bound (${max})`);
        }

        const randInt = Math.floor(Math.random() * (max - min));

        return min + randInt;
    },

    getRandomEntryFromArray: <T>(array: T[]): T => {
        return array[generalUtil.getRandomIntBetween(0, array.length)];
    }
};

export default generalUtil;