import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import Layout from './components/layout/layout';
import NotFound from './pages/not-found/not-found';
import RouterScroll from './util/router-scroll';
import Contact from './pages/contact/contact';
import Squirrels from './pages/sections/squirrels';
import Beavers from './pages/sections/beavers';
import Cubs from './pages/sections/cubs';
import Scouts from './pages/sections/scouts';
import Explorers from './pages/sections/explorers';
import Network from './pages/sections/network';
import JoinUs from './pages/join/join';
import Page from './util/page';
import { meta } from './util/data/meta';
import Volunteer from './pages/volunteer/volunteer';

function App() {
  return (
    <BrowserRouter>
      <RouterScroll />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route
            index
            element={<Page component={<Home />} meta={meta['/']} />}
          />
          <Route
            path='contact'
            element={<Page component={<Contact />} meta={meta['/contact']} />}
          />
          <Route
            path='squirrels'
            element={
              <Page component={<Squirrels />} meta={meta['/squirrels']} />
            }
          />
          <Route
            path='beavers'
            element={<Page component={<Beavers />} meta={meta['/beavers']} />}
          />
          <Route
            path='cubs'
            element={<Page component={<Cubs />} meta={meta['/cubs']} />}
          />
          <Route
            path='scouts'
            element={<Page component={<Scouts />} meta={meta['/scouts']} />}
          />
          <Route
            path='explorers'
            element={
              <Page component={<Explorers />} meta={meta['/explorers']} />
            }
          />
          <Route
            path='network'
            element={<Page component={<Network />} meta={meta['/network']} />}
          />
          <Route
            path='join'
            element={<Page component={<JoinUs />} meta={meta['/join']} />}
          />
          <Route
            path='volunteer'
            element={
              <Page component={<Volunteer />} meta={meta['/volunteer']} />
            }
          />
          <Route
            path='*'
            element={<Page component={<NotFound />} meta={meta['/404']} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
