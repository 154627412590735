import * as React from 'react';

export interface ClickOutsideHandler {
    ref: React.RefObject<HTMLDivElement>;
}

export const useClickOutsideHandler = (handleClickOutside: () => void, active: boolean): ClickOutsideHandler => {
    const ref = React.useRef(null);

    React.useEffect(() => {
        const checkClickOutside = (ev: MouseEvent) => {
            if (ref.current &&
                ev.target &&
                active &&
                !ev.composedPath().includes(ref.current)
            ) {
                handleClickOutside();
            }
        };

        document.addEventListener('click', checkClickOutside);

        return () => {
            document.removeEventListener('click', checkClickOutside);
        }
    });

    return { ref };
}