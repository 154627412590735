import React from 'react';
import { theme } from '../../styles/theme';
import { createPortal } from 'react-dom';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useClickOutsideHandler } from '../../util/click-outside-handler';
import modalStyles from './modal.styles';

export type ModalProps = {
  isOpen: boolean;
  title?: string;
  bannerColour?: string;
  bannerTextColour?: string;
  closeIcon?: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const Modal = ({
  isOpen,
  title,
  bannerColour,
  bannerTextColour,
  closeIcon = true,
  onClose,
  children,
}: ModalProps) => {
  const [checkClickOutside, setCheckClickOutside] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      setCheckClickOutside(false);
    }
  }, [isOpen]);

  const { ref } = useClickOutsideHandler(onClose, checkClickOutside);

  return createPortal(
    <div
      className={css(
        commonStyles.flex,
        commonStyles.centreHorizontal,
        commonStyles.centreVertical,
        commonStyles.fullWidth,
        commonStyles.fullHeight,
        modalStyles.backdrop,
      )}
      style={{
        visibility: isOpen ? 'visible' : 'hidden',
      }}
    >
      <div
        className={css(
          commonStyles.shadow,
          commonStyles.smooth,
          commonStyles.fitContentHeight,
          commonStyles.fitContentWidth,
          modalStyles.modal,
        )}
        style={{
          transform: isOpen ? 'scale(1)' : 'scale(0)',
        }}
        onTransitionEnd={() => {
          if (isOpen) {
            setCheckClickOutside(true);
          }
        }}
        ref={ref}
      >
        {(title || !!closeIcon) && (
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreVertical,
              commonStyles.gapLarge,
              commonStyles.spaceBetween,
              modalStyles.titleBar,
            )}
            style={{
              backgroundColor: bannerColour,
              color: bannerTextColour,
            }}
          >
            <h3 className={css(commonStyles.noMargin, commonStyles.largeText)}>
              {title}
            </h3>
            {closeIcon && (
              <button
                className={css(commonStyles.nativeReset)}
                onClick={onClose}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  color={bannerTextColour ?? theme.colour.grey[40]}
                  size='lg'
                />
              </button>
            )}
          </div>
        )}
        <div className={css(modalStyles.childrenContainer)}>{children}</div>
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
