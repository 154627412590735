import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button/button';
import { CustomIconName } from '../../../../../components/custom-icon/custom-icons';
import applicationVariables from '../../../../../util/variables';
import { useSessionContext } from '../../../../../util/session.context';

export type SectionDetailsWhereWhenProps = {
  fullSectionName: string;
  dateTime: string;
  isVisible?: boolean;
};

const SectionDetailsWhereWhen = ({
  fullSectionName,
  dateTime,
  isVisible,
}: SectionDetailsWhereWhenProps) => {
  const { isMobileMenuOpen } = useSessionContext();
  return (
    <>
      <p>
        {fullSectionName} meet on {dateTime}. Meetings are during standard
        school term-time.
      </p>
      <p>
        All sections meet at Sheepfair Community Centre, Sheepfair, Rugeley,
        WS15 2AT.
      </p>
      <Link to={applicationVariables.gMapsDirUrl} target='_blank' tabIndex={-1}>
        <Button
          label='Directions'
          icon={CustomIconName.ArrowCircleRight}
          borderOnHover
          fontSize='20px'
          focusable={isVisible && !isMobileMenuOpen}
        />
      </Link>
    </>
  );
};

export default SectionDetailsWhereWhen;
