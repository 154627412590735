import { StyleSheet } from 'aphrodite';
import { theme } from '../../../../styles/theme';

const sectionDetailsStyles = StyleSheet.create({
  title: {
    fontSize: 'calc(2.5vw + 2.5vh)',
    fontWeight: 800,
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 'calc(3vw + 3vh)',
    },
    margin: 0,
  },
  question: {
    color: theme.colour.purple,
    fontSize: '26px',
  },
  columnWidth: {
    maxWidth: '48%',
  },
});

export default sectionDetailsStyles;
