import { css } from 'aphrodite';
import React from 'react';
import cookieConsentSyles from './cookie-consent.styles';
import { commonStyles } from '../../styles/common.styles';
import { files, media } from '../../util/media';
import Button from '../button/button';
import { useScreenSizeContext } from '../../util/screensize.context';
import UnderlineLink from '../underline-link/underline-link';
import { theme } from '../../styles/theme';
import Toggle from '../toggle/toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSessionContext } from '../../util/session.context';
import { CookieConsentMode } from '../../types/general';

export type CookieConsentProps = {
  mode: CookieConsentMode;
  onAccept: (close?: boolean) => void;
  onReject: (close?: boolean) => void;
};

const CookieConsent = ({ mode, onAccept, onReject }: CookieConsentProps) => {
  const { screenWidth } = useScreenSizeContext();

  const { isCookieConsentOpen, closeCookieConsent, cookieConsentStatus } =
    useSessionContext();

  const { isMobileMenuOpen } = useSessionContext();

  const [consentChanged, setConsentChanged] = React.useState(false);

  const mobileSize = React.useMemo(() => screenWidth <= 700, [screenWidth]);

  return (
    <div
      className={css(
        cookieConsentSyles.container,
        mobileSize
          ? cookieConsentSyles.containerMobile
          : cookieConsentSyles.containerDesktop,
        commonStyles.shadow,
        isCookieConsentOpen
          ? cookieConsentSyles.open
          : cookieConsentSyles.closed,
      )}
    >
      <div
        className={css(
          commonStyles.flex,
          commonStyles.spaceBetween,
          commonStyles.centreVertical,
        )}
      >
        <h3 className={css(cookieConsentSyles.title)}>Want a Cookie?</h3>
        <div style={{ width: '10%' }}>
          <img
            src={media.misc.cookie.src}
            alt={media.misc.cookie.alt}
            width={'100%'}
          />
        </div>
        {mode !== 'initial' && (
          <button
            className={css(commonStyles.nativeReset)}
            onClick={() => {
              closeCookieConsent();
              setConsentChanged(false);
            }}
          >
            <FontAwesomeIcon
              icon={faXmark}
              size='lg'
              color={theme.colour.grey[40]}
            />
          </button>
        )}
      </div>
      <p className={css(cookieConsentSyles.text)}>
        We may use some essential cookies in order to make this site work
        correctly.
      </p>
      <p className={css(cookieConsentSyles.text)}>
        We&apos;d like to use <b>analytics</b> cookies in order for us to
        understand how you use this website. We will only use these if you
        consent.
      </p>
      {mode === 'initial' ? (
        <div
          className={css(
            commonStyles.flex,
            commonStyles.gapXXLarge,
            commonStyles.centreHorizontal,
          )}
        >
          <Button
            label='Accept'
            fontSize='24px'
            bold
            dropShadow
            borderOnHover
            onClick={() => onAccept(true)}
            focusable={!isMobileMenuOpen}
          />
          <Button
            label='Reject'
            fontSize='24px'
            bold
            dropShadow
            borderOnHover
            onClick={() => onReject(true)}
            focusable={!isMobileMenuOpen}
          />
        </div>
      ) : (
        <div>
          <p>
            <b>
              You previously{' '}
              {mode === 'amend-consent' ? 'consented to' : 'rejected'} analytics
              cookies. To change this, use the toggle below
            </b>
          </p>
          <Toggle
            enabled={cookieConsentStatus ?? false}
            enabledLabel='Accept'
            disabledLabel='Reject'
            onClick={() => {
              if (cookieConsentStatus === true) {
                onReject();
              } else {
                onAccept();
              }

              setConsentChanged(true);
            }}
          />
          {consentChanged && (
            <p
              className={css(commonStyles.textAlignCentre)}
              style={{ color: theme.colour.green }}
            >
              Cookie consent settings successfully changed!
            </p>
          )}
        </div>
      )}
      <p className={css(cookieConsentSyles.text)}>
        You can manage your consent at any time. You can do this by using the
        link in the footer of every page.
      </p>
      <p className={css(cookieConsentSyles.text, commonStyles.noMarginBottom)}>
        More information on Cookies, and how we use them, can be found in our{' '}
        <UnderlineLink
          text='Privacy Policy'
          link={files.policies.privacy}
          textColour={theme.colour.purple}
          inline
          linkInNewTab
        />
      </p>
    </div>
  );
};

export default CookieConsent;
