import React from 'react';
import BaseSectionCard from '../../../../components/section-card/base/base-section-card';
import { OpenPosition, SectionStyle } from '../../../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../../../styles/common.styles';
import positionCardStyles from './position-card.styles';
import AnimatedButtonLearnMore from '../../../../components/animated-button/learn-more/animated-button-learn-more';
import { theme } from '../../../../styles/theme';
import { ButtonVariant } from '../../../../components/button/button';
import PositionDetailsModal from '../position-details/position-details-modal';

export type PositionCardProps = {
  position: OpenPosition;
};

export type PositionModalColourScheme = {
  bg: string;
  text: string;
  button: ButtonVariant;
};

const getSectionColourScheme = (
  section: SectionStyle,
): PositionModalColourScheme => {
  switch (section) {
    case 'Squirrels': {
      return {
        bg: theme.colour.red,
        text: theme.colour.white,
        button: 'primary-red',
      };
    }
    case 'Beavers': {
      return {
        bg: theme.colour.blue,
        text: theme.colour.white,
        button: 'primary-blue',
      };
    }
    case 'Cubs': {
      return {
        bg: theme.colour.green,
        text: theme.colour.black,
        button: 'primary-green',
      };
    }
    case 'Scouts': {
      return {
        bg: theme.colour.teal,
        text: theme.colour.white,
        button: 'primary-teal',
      };
    }
    case 'Explorers': {
      return {
        bg: theme.colour.navy,
        text: theme.colour.white,
        button: 'primary-navy',
      };
    }
    case 'Network': {
      return {
        bg: theme.colour.black,
        text: theme.colour.white,
        button: 'primary-black',
      };
    }
    case 'General': {
      return {
        bg: theme.colour.purple,
        text: theme.colour.white,
        button: 'primary',
      };
    }
  }
};

const PositionCard = ({ position }: PositionCardProps) => {
  const [isHover, setIsHover] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const colourScheme = React.useMemo(
    () => getSectionColourScheme(position.section),
    [position],
  );

  return (
    <>
      <PositionDetailsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        position={position}
        colourScheme={colourScheme}
      />
      <BaseSectionCard
        variant={position.section}
        onHover={() => setIsHover(true)}
        onUnhover={() => setIsHover(false)}
        onClick={() => setIsModalOpen(true)}
        height='calc(100% - 3vw)'
      >
        <div className={css(commonStyles.flex, commonStyles.justifyEnd)}>
          <div style={{ width: '40%' }}>
            <AnimatedButtonLearnMore animate={isHover} />
          </div>
        </div>
        <div
          className={css(
            commonStyles.bold,
            commonStyles.textWhite,
            positionCardStyles.title,
          )}
        >
          {position.title}
        </div>
      </BaseSectionCard>
    </>
  );
};

export default PositionCard;
