import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../../../styles/common.styles';
import Card from '../../../../components/card/card';
import { theme } from '../../../../styles/theme';
import ContactCardContent from '../../../../components/section-card/contact/content/contact-card-content';
import { getContactData } from '../../../../util/data/contact';
import { ContactData, Section } from '../../../../types/general';
import { useScreenSizeContext } from '../../../../util/screensize.context';
import Button from '../../../../components/button/button';
import { CustomIconName } from '../../../../components/custom-icon/custom-icons';
import { Page } from '../../../../types/navigation';
import sectionContactStyles from './section-contact.styles';
import applicationVariables from '../../../../util/variables';
import { useSessionContext } from '../../../../util/session.context';

export type SectionContactProps = {
  section: Section;
};

type SectionContactCardColours = {
  contactBg: string;
  contactTxt: string;
  ctaBg: string;
  ctaTxt: string;
};

const getCardColours = (section: Section): SectionContactCardColours => {
  switch (section) {
    case 'Squirrels': {
      return {
        contactBg: theme.colour.red,
        contactTxt: theme.colour.white,
        ctaBg: theme.colour.pink,
        ctaTxt: theme.colour.black,
      };
    }
    case 'Beavers': {
      return {
        contactBg: theme.colour.blue,
        contactTxt: theme.colour.white,
        ctaBg: theme.colour.yellow,
        ctaTxt: theme.colour.black,
      };
    }
    case 'Cubs': {
      return {
        contactBg: theme.colour.green,
        contactTxt: theme.colour.black,
        ctaBg: theme.colour.navy,
        ctaTxt: theme.colour.white,
      };
    }
    case 'Scouts': {
      return {
        contactBg: theme.colour.teal,
        contactTxt: theme.colour.white,
        ctaBg: theme.colour.purple,
        ctaTxt: theme.colour.white,
      };
    }
    case 'Explorers': {
      return {
        contactBg: theme.colour.navy,
        contactTxt: theme.colour.white,
        ctaBg: theme.colour.green,
        ctaTxt: theme.colour.black,
      };
    }
    case 'Network': {
      return {
        contactBg: theme.colour.black,
        contactTxt: theme.colour.white,
        ctaBg: theme.colour.purple,
        ctaTxt: theme.colour.white,
      };
    }
  }
};

const SectionContact = ({ section }: SectionContactProps) => {
  const { isScreenSmallOrLess } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();

  const cardColours = React.useMemo(() => getCardColours(section), [section]);

  return (
    <div className={css(commonStyles.screenMargin)}>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.gapXLarge,
          isScreenSmallOrLess && commonStyles.flexColumn,
        )}
      >
        <div
          id='section-contact'
          style={{
            width: isScreenSmallOrLess ? '100%' : '50%',
            color: cardColours.contactTxt,
          }}
        >
          <Card
            customClass={sectionContactStyles.card}
            backgroundColor={cardColours.contactBg}
            fullWidth
            fullHeight
            padding={
              isScreenSmallOrLess
                ? 'calc(2.4vw + 2.4vh)'
                : 'calc(1.8vw + 1.8vh)'
            }
          >
            <h3
              className={css(commonStyles.noMargin)}
              style={{
                fontSize: isScreenSmallOrLess
                  ? 'calc(3vw + 3vh)'
                  : 'calc(2vw + 2vh)',
                fontWeight: 800,
              }}
            >
              Section Leader
            </h3>
            {section === 'Network' ? (
              <p>
                If you have any further questions about Network, please feel
                free to contact the District Scout Network Commissioner below.
              </p>
            ) : (
              <p>
                If you have any further questions about {section}, or would just
                like a chat to discuss your child starting (or continuing!)
                their journey with us, please feel free to contact the{' '}
                {section.slice(0, -1)} Section Leader below.
              </p>
            )}

            <div
              className={css(
                commonStyles.flex,
                commonStyles.flexColumn,
                commonStyles.flexGrow,
                commonStyles.spaceAround,
              )}
            >
              <ContactCardContent
                contactData={getContactData(section) as ContactData}
                size='large'
                color={
                  section === 'Cubs' ? theme.colour.black : theme.colour.white
                }
              />
            </div>
          </Card>
        </div>
        <div
          style={{
            width: isScreenSmallOrLess ? '100%' : '50%',
            color: cardColours.ctaTxt,
          }}
        >
          <Card
            customClass={sectionContactStyles.card}
            backgroundColor={cardColours.ctaBg}
            fullWidth
            fullHeight
            padding={
              isScreenSmallOrLess
                ? 'calc(2.4vw + 2.4vh)'
                : 'calc(1.8vw + 1.8vh)'
            }
          >
            <h3
              className={css(
                commonStyles.noMarginTop,
                commonStyles.marginBottomXXSmall,
              )}
              style={{
                fontSize: isScreenSmallOrLess
                  ? 'calc(3vw + 3vh)'
                  : 'calc(2vw + 2vh)',
                fontWeight: 800,
              }}
            >
              Interested?
            </h3>
            <div
              className={css(
                commonStyles.flex,
                commonStyles.gapLarge,
                commonStyles.flexColumn,
                commonStyles.spaceAround,
                commonStyles.flexGrow,
              )}
            >
              <div>
                <h4
                  style={{ fontSize: '20px' }}
                  className={css(commonStyles.noMargin)}
                >
                  Enrolling a child into {section}
                </h4>
                <p className={css(commonStyles.noMarginTop)}>
                  We currently operate a waiting list system using our Scouting
                  management system, Online Scout Manager. Please fill in the
                  details in the attached form and the {section} team will get
                  back to you soon.
                </p>
                <Button
                  label='Waiting List'
                  variant='secondary-black'
                  fontSize='20px'
                  bold
                  dropShadow
                  icon={CustomIconName.ArrowCircleRight}
                  link={applicationVariables.osmWaitListUrl}
                  linkInNewTab
                  focusable={!isMobileMenuOpen}
                />
              </div>
              <div>
                <h4
                  style={{ fontSize: '20px' }}
                  className={css(commonStyles.noMargin)}
                >
                  Volunteering at {section}
                </h4>
                <p className={css(commonStyles.noMarginTop)}>
                  We&apos;re always looking for new volunteers to help deliver
                  our programme and make an impact on young peoples&apos; lives.
                </p>
                <Button
                  label='Learn More'
                  variant='secondary-black'
                  fontSize='20px'
                  bold
                  dropShadow
                  icon={CustomIconName.ArrowCircleRight}
                  link={Page.Volunteer}
                  focusable={!isMobileMenuOpen}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SectionContact;
