import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const notFoundStyles = StyleSheet.create({
  button: {
    margin: `${theme.size.small} 0`,
  },
  contentContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '40vw',
  },

  title: {
    margin: 0,
    fontSize: 'calc(4vw + 4vh)',
  },
  subtext: {
    fontSize: 'calc(1.5vw + 1.5vh)',
    maxWidth: '60%',
    textAlign: 'center',
    [`@media (min-width: ${theme.breakpoints.large}px)`]: {
      maxWidth: '50%',
    },
  },
});

export default notFoundStyles;
