import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import '@fontsource-variable/nunito-sans';
import App from './App';
import reportWebVitals from './util/reportWebVitals';
import { ScreenSizeProvider } from './util/screensize.context';
import { SessionContextProvider } from './util/session.context';
import Analytics from './util/analytics/analytics';
import {
  checkCookie,
  initialiseGAForPerformance,
  logPerformance,
} from './util/analytics/analytics-helper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <SessionContextProvider>
      <ScreenSizeProvider>
        <Analytics />
        <App />
      </ScreenSizeProvider>
    </SessionContextProvider>
  </React.StrictMode>,
);

if (checkCookie(document.cookie)) {
  initialiseGAForPerformance();
  reportWebVitals(logPerformance);
}
