import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../../../styles/common.styles';
import contactDetailsStyles from '../../../contact/details/contact-details.styles';
import Card from '../../../../components/card/card';
import { theme } from '../../../../styles/theme';
import sectionDetailsStyles from './section-details.styles';
import {
  SectionDetailData,
  SectionDetailDataItem,
} from '../../../../types/general';
import { useScreenSizeContext } from '../../../../util/screensize.context';
import CollapsibleCard from '../../../../components/collapsible-card/collapsible-card';

type SectionDetailsFormationProps = {
  data: SectionDetailData;
};

export type SectionDetailsProps = SectionDetailsFormationProps & {
  formation: 'staggered' | 'pyramid';
  onCardChange?: (openIndexes: number[]) => void;
};

type SectionDetailsMainCardProps = {
  data: SectionDetailDataItem;
};

const MainCard = ({ data }: SectionDetailsMainCardProps) => {
  return (
    <Card padding={theme.size.medium}>
      <h4
        className={css(
          commonStyles.noMargin,
          commonStyles.marginBottomXSmall,
          sectionDetailsStyles.question,
        )}
      >
        {data.question}
      </h4>
      {typeof data.answer === 'string' ? (
        <p className={css(commonStyles.noMargin)}>{data.answer}</p>
      ) : (
        <div>{data.answer}</div>
      )}
    </Card>
  );
};

const StaggeredFormation = ({ data }: SectionDetailsFormationProps) => {
  const { isScreenSmallOrLess } = useScreenSizeContext();

  return isScreenSmallOrLess ? (
    <div
      className={css(
        commonStyles.flex,
        commonStyles.flexColumn,
        commonStyles.gapLarge,
      )}
    >
      {data.length >= 1 && <MainCard data={data[0]} />}
      {data.length >= 2 && <MainCard data={data[1]} />}
      {data.length >= 3 && <MainCard data={data[2]} />}
    </div>
  ) : (
    <div className={css(commonStyles.flex, commonStyles.spaceBetween)}>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          commonStyles.gapXXLarge,
          sectionDetailsStyles.columnWidth,
        )}
      >
        {data.length >= 1 && <MainCard data={data[0]} />}
        {data.length >= 3 && <MainCard data={data[2]} />}
      </div>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.centreVertical,
          sectionDetailsStyles.columnWidth,
        )}
      >
        {data.length >= 2 && <MainCard data={data[1]} />}
      </div>
    </div>
  );
};

const PyramidFormation = ({ data }: SectionDetailsFormationProps) => {
  const { isScreenSmallOrLess } = useScreenSizeContext();
  return (
    <div
      className={css(
        commonStyles.flex,
        commonStyles.flexColumn,
        commonStyles.gapLarge,
      )}
    >
      {data.length >= 1 && (
        <div className={css(commonStyles.fullWidth)}>
          <MainCard data={data[0]} />
        </div>
      )}
      <div
        className={css(
          commonStyles.flex,
          commonStyles.spaceBetween,
          isScreenSmallOrLess && commonStyles.flexColumn,
          isScreenSmallOrLess && commonStyles.gapLarge,
        )}
      >
        {data.length >= 2 && (
          <div
            className={css(
              !isScreenSmallOrLess && sectionDetailsStyles.columnWidth,
            )}
          >
            <MainCard data={data[1]} />
          </div>
        )}
        {data.length >= 3 && (
          <div
            className={css(
              !isScreenSmallOrLess && sectionDetailsStyles.columnWidth,
            )}
          >
            <MainCard data={data[2]} />
          </div>
        )}
      </div>
    </div>
  );
};

const SectionDetails = ({
  data,
  formation,
  onCardChange,
}: SectionDetailsProps) => {
  const [openCardIndexes, setOpenCardIndexes] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (onCardChange) {
      onCardChange(openCardIndexes);
    }
  }, [openCardIndexes]);

  return (
    <div
      className={css(
        commonStyles.flex,
        contactDetailsStyles.background,
        commonStyles.centreHorizontal,
      )}
    >
      <div
        className={css(commonStyles.screenPadding, commonStyles.maxScreenWidth)}
      >
        <div className={css(commonStyles.marginBottomLarge)}>
          <Card padding={`${theme.size.xsmall} ${theme.size.medium}`}>
            <h3 className={css(sectionDetailsStyles.title)}>
              Dig into the details
            </h3>
          </Card>
        </div>
        {formation === 'staggered' ? (
          <StaggeredFormation data={data} />
        ) : (
          <PyramidFormation data={data} />
        )}
        <div
          className={css(
            commonStyles.marginTopLarge,
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapLarge,
          )}
        >
          {data.slice(3).map((data, index) => (
            <CollapsibleCard
              id={`collapsible-card-${index}`}
              key={index}
              title={
                <h4
                  className={css(
                    commonStyles.noMargin,
                    sectionDetailsStyles.question,
                  )}
                >
                  {data.question}
                </h4>
              }
              onClick={() => {
                if (openCardIndexes.includes(index)) {
                  setOpenCardIndexes(
                    openCardIndexes.filter((i) => i !== index),
                  );
                } else {
                  setOpenCardIndexes([...openCardIndexes, index]);
                  document
                    .getElementById(`collapsible-card-${index}`)
                    ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }
              }}
              content={
                typeof data.answer === 'string' ? (
                  <p className={css(commonStyles.noMargin)}>{data.answer}</p>
                ) : (
                  <div>{data.answer}</div>
                )
              }
              padding={theme.size.medium}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionDetails;
