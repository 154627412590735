import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

const homeIntroductionStyles = StyleSheet.create({
  parentContainer: {
    display: 'grid',
    justifyContent: 'center',
  },
  container: {
    margin: `1.5vw ${theme.margins.screenEdges}`,
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    maxWidth: '1440px',
    alignSelf: 'center',
  },
  contentContainerLarge: {
    gap: theme.size.xxxlarge,
  },
  contentContainerMedium: {
    gap: theme.size.large,
  },
  contentContainerSmall: {
    gap: theme.size.large,
    flexDirection: 'column',
  },
  heading: {
    fontSize: 'calc(1.8vw + 1.8vh)',
    fontWeight: 'bold',
  },
  headingMobile: {
    fontSize: 'calc(2.2vw + 2.2vh)',
    fontWeight: 'bold',
  },
  enhancedHeading: {
    color: theme.colour.purple,
    fontSize: 'calc(2.2vw + 2.2vh)',
    fontWeight: 800,
    textWrap: 'nowrap',
  },
  enhancedHeadingMobile: {
    color: theme.colour.purple,
    fontSize: 'calc(3vw + 3vh)',
    fontWeight: 800,
    textWrap: 'nowrap',
  },
  introductionText: {
    fontWeight: 'normal',
    margin: `${theme.size.small} 0`,
  },
  introductionTextLarger: {
    fontSize: '26px',
  },
  introductionTextMobile: {
    fontSize: '24px',
  },
  imageContainer: {
    margin: `${theme.size.small} 0`,
  },
  imageContainerMobile: {
    margin: theme.size.small,
    display: 'flex',
    justifyContent: 'center',
  },
});

export default homeIntroductionStyles;
