import { StyleSheet } from 'aphrodite';

const sectionContactStyles = StyleSheet.create({
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default sectionContactStyles;
