import React from 'react';
import { theme } from '../../styles/theme';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import cardStyles from './card.styles';

export type CardProps = {
  children: React.ReactNode;
  id?: string;
  customClass?: object;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundBlend?: boolean;
  padding?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  onUnhover?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  clickable?: boolean;
  focusable?: boolean;
};

const Card = ({
  children,
  id,
  customClass,
  backgroundColor = theme.colour.white,
  backgroundImage,
  backgroundBlend,
  padding,
  fullWidth = false,
  fullHeight = false,
  onClick,
  onHover,
  onUnhover,
  onFocus,
  onBlur,
  clickable,
  focusable,
}: CardProps) => {
  return clickable || onClick ? (
    <button
      id={id}
      className={css(
        cardStyles.nativeReset,
        commonStyles.clickable,
        commonStyles.shadow,
        cardStyles.card,
        !fullWidth && cardStyles.fitContent,
        onClick && commonStyles.clickable,
        commonStyles.smooth,
        backgroundBlend && cardStyles.backgroundBlend,
        !!backgroundImage && cardStyles.backgroundImage,
        customClass,
      )}
      style={{
        backgroundColor,
        backgroundImage,
        padding: padding ?? theme.size.small,
        height: fullHeight
          ? `calc(100% - calc(2 * ${padding ?? theme.size.small}))`
          : undefined,
      }}
      onClick={onClick}
      onMouseOver={onHover}
      onMouseLeave={onUnhover}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={focusable ? 0 : -1}
    >
      {children}
    </button>
  ) : (
    <div
      id={id}
      className={css(
        commonStyles.shadow,
        cardStyles.card,
        !fullWidth && cardStyles.fitContent,
        commonStyles.smooth,
        backgroundBlend && cardStyles.backgroundBlend,
        !!backgroundImage && cardStyles.backgroundImage,
        customClass,
      )}
      style={{
        backgroundColor,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
        padding: padding ?? theme.size.small,
        height: fullHeight
          ? `calc(100% - calc(2 * ${padding ?? theme.size.small}))`
          : undefined,
      }}
      onMouseOver={onHover}
      onMouseLeave={onUnhover}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={focusable ? 0 : -1}
    >
      {children}
    </div>
  );
};

export default Card;
