import React from 'react';
import Header from '../../components/header/header';
import { media } from '../../util/media';
import generalUtil from '../../util/general';
import SectionIntroduction from './shared/introduction/section-introduction';
import SectionDetails from './shared/details/section-details';
import { SectionDetailData } from '../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import SectionDetailsBadgePlacement from './shared/details/badge-placement/section-details-badge-placement';
import SectionDetailsPromise from './shared/details/promise/section-details-promise';
import SectionDetailsSafeguarding from './shared/details/safeguarding/section-details-safeguarding';
import SectionDetailsMoreInformation from './shared/details/more-information/section-details-more-information';
import SectionDetailsWhereWhen from './shared/details/where-when/section-details-where-when';
import SectionDetailsMovingOn from './shared/details/moving-on/section-details-moving-on';
import SectionContact from './shared/contact/section-contact';
import Button from '../../components/button/button';
import { CustomIconName } from '../../components/custom-icon/custom-icons';
import { useScreenSizeContext } from '../../util/screensize.context';
import applicationVariables from '../../util/variables';
import { useSessionContext } from '../../util/session.context';

const Cubs = () => {
  const [openCardIndexes, setOpenCardIndexes] = React.useState<number[]>([]);

  const { isScreenXSmall } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();

  const detailData: SectionDetailData = [
    {
      question: 'What do Cubs do?',
      answer: (
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapMedium,
          )}
        >
          <p className={css(commonStyles.noMargin)}>
            Being a Cub is all about growing and learning in small but mighty
            ways. Here are some of the things you&apos;ll get up to with your
            new friends.
          </p>
          <div>
            <b className={css(commonStyles.text)}>Going on adventures</b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Race down a river. Tell stories by torchlight. Fall asleep beneath
              the stars. Alongside your Pack, you&apos;ll spend plenty of time
              in the great outdoors. Together, you might build a den in your
              local park, or create an edible raft out of sweets, or go on a
              moonlit hike through your hometown. And even though you might not
              be ready to climb Mount Everest just yet, you&apos;re guaranteed
              to have plenty of adventures on your own doorstep, because being a
              Cub is all about making the most of what you have, wherever and
              whoever you are.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>Learning new skills</b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Cubs learn by doing, and so will you. Some of the skills you
              develop will be practical, like knowing how to cook a delicious
              meal or give someone first aid. Others will allow you to become a
              master at your chosen hobby, or help you to succeed in whichever
              job you decide to do when you grow up. But the most important
              skills you&apos;ll learn at Cubs are the ones that will make you
              feel confident and happy in your own skin. We call these character
              skills, and they include things like integrity - which means being
              honest and doing what you think is right - and initiative - which
              means knowing how to take the lead on something without being
              asked. Whatever skills you&apos;d like to learn, it&apos;s all
              about having the courage to try new things and learn from them.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>Helping others</b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Cubs work as a team to help other people. Together, you&apos;ll
              learn about global issues and what we can all do to help solve
              them. You&apos;ll also make an impact in your own community,
              through activities such as campaigning to save your local library,
              collecting donations for a foodbank, or planting trees in a
              neighbouring park.
            </p>
          </div>
        </div>
      ),
    },
    {
      question: 'Who can join Cubs?',
      answer: `Anyone between the ages of 8 and 10½, whether they've been part of any younger section or not. Here at Scouts, we’re open to people of all genders, backgrounds, and faiths (including people of no faith). We're committed to reaching out to new and different families and communities and including people who have never tried Scouts before.`,
    },
    {
      question: 'Where & when do Cubs meet?',
      answer: (
        <SectionDetailsWhereWhen
          fullSectionName='Cubs'
          dateTime='Tuesdays, from 18:15 - 19:45'
          isVisible
        />
      ),
    },
    {
      question: 'Do Cubs have a uniform?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            Cub uniform consists of a green sweatshirt with your badges sewn on
            and a coloured scarf or &quot;necker&quot; to represent 2nd Rugeley.
            There are lots of other optional accessories you can wear such as
            hats, hoodies, navy blue trousers or shorts.
          </p>
          <p>
            Wearing a uniform is comfy and practical. It means no one feels
            uncomfortable or left out and helps everyone feel part of the Pack.
            It also gives you a place to show off all the badges you earn.
          </p>
          <p>
            Uniform can either be bought from the official Scout Store or from a
            local supplier (such as Uniforms Plus). If you&apos;re stuck, please
            feel free to ask the section leader to tell you more about what to
            buy and where to buy it. If cost is an issue, they will be more than
            happy to help.
          </p>
          <div
            className={css(
              commonStyles.flex,
              isScreenXSmall ? commonStyles.gapSmall : commonStyles.gapLarge,
              isScreenXSmall && commonStyles.flexColumn,
            )}
          >
            <Button
              label='Scout Shop'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.scoutShopUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
            <Button
              label='Uniforms Plus'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.uniformVendorUrl}
              linkInNewTab
              focusable={openCardIndexes.includes(0) && !isMobileMenuOpen}
            />
          </div>
        </>
      ),
    },
    {
      question: 'Where do badges go?',
      answer: (
        <SectionDetailsBadgePlacement
          section='Cubs'
          isVisible={openCardIndexes.includes(1)}
        />
      ),
    },
    {
      question: 'What is an investiture?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            As well as enjoying plenty of adventures, being a Cub is about going
            on a journey to understand who you are and what you stand for. When
            you join the Pack, you&apos;ll explore these ideas by making a
            promise. A promise is a set of words that mean something to you,
            which you try to follow everyday.
          </p>
          <p className={css(commonStyles.noMarginBottom)}>
            Making the promise is a big celebration within the Pack. Every time
            a new Cub decides to join permanently, they chat through their
            promise with their Cub leader before saying it out loud in front of
            their fellow Cubs. Family and friends might come along to see this,
            too. The process is known as being &quot;invested&quot; into Cubs,
            and it usually takes place a few weeks into your Cub experience,
            once you&apos;ve had time to settle in.
          </p>
        </>
      ),
    },
    {
      question: 'What is the Cub Scout Promise?',
      answer: (
        <SectionDetailsPromise
          section='Cubs'
          introduction='Everyone is unique but there are some things all Cubs agree on - such as the importance of treating everyone in the Pack with kindness, and doing their best to care for the community and wider world in which they live. Cubs make a promise to do their best to make a positive contribution to society. Depending on their own beliefs, they might also promise to live by their faith. Cubs will choose the promise that best suits them.'
          data={[
            {
              variant: 'Atheist or of no faith background',
              promise: [
                'I promise that I will do my best',
                'to uphold our Scout values, to do my duty to The King,',
                'to help other people',
                'and to keep the Cub Scout Law.',
              ],
            },
            {
              variant: 'Buddhist',
              promise: [
                'I promise that I will do my best',
                'to seek refuge in the Triple Gem, to do my duty to The King,',
                'to act with compassion towards all life',
                'and to keep the Cub Scout Law.',
              ],
            },
            {
              variant: 'Christian',
              promise: [
                'I promise that I will do my best',
                'to do my duty to God and to The King,',
                'to help other people',
                'and to keep the Cub Scout Law.',
              ],
            },
            {
              variant: 'Hindu',
              promise: [
                'I promise that I will do my best',
                'to follow my dharma and do my duty to The King,',
                'to act with compassion towards all life',
                'and to keep the Cub Scout Law.',
              ],
            },
            {
              variant: 'Humanist',
              promise: [
                'I promise that I will do my best',
                'to uphold our Scout values, to do my duty to The King,',
                'to help other people',
                'and to keep the Cub Scout Law.',
              ],
            },
            {
              variant: 'Jewish',
              promise: [
                'I promise that I will do my best',
                'to do my duty to God and to The King,',
                'to help other people',
                'and to keep the Cub Scout Law.',
              ],
            },
            {
              variant: 'Muslim',
              promise: [
                'I promise that I will do my best',
                'to do my duty to Allah and then to The King,',
                'to help other people',
                'and to keep the Cub Scout Law.',
              ],
            },
            {
              variant: 'Sikh',
              promise: [
                'I promise that I will do my best',
                'to do my duty to Waheguru and to The King,',
                'to help other people',
                'and to keep the Cub Scout Law.',
              ],
            },
          ]}
        />
      ),
    },
    {
      question: `I'm concerned about safety/safeguarding`,
      answer: (
        <SectionDetailsSafeguarding isVisible={openCardIndexes.includes(4)}>
          <p>
            All of our leaders are trained volunteers, working to make sure
            Scouting is safe and open to all. The programme delivered is
            specifically designed to be accessible for all members of Cubs.
          </p>
        </SectionDetailsSafeguarding>
      ),
    },
    {
      question: 'What happens after Cubs?',
      answer: (
        <SectionDetailsMovingOn
          section='Cubs'
          isVisible={openCardIndexes.includes(5)}
        />
      ),
    },
    {
      question: `I'd like more information`,
      answer: (
        <SectionDetailsMoreInformation
          section='Cubs'
          isVisible={openCardIndexes.includes(6)}
        />
      ),
    },
  ];

  const bgImage = React.useMemo(
    () =>
      generalUtil.getRandomEntryFromArray(media.images.sections.cubs.header),
    [],
  );

  return (
    <>
      <Header
        image={bgImage}
        title='Cubs'
        subtitle='Ages 8 - 10½'
        backgroundPosition={bgImage.pos}
      />
      <SectionIntroduction
        heading='Develop new skills. Soar to great heights. Being a Cub opens up a whole other world.'
        image={media.images.sections.cubs.introduction}
      >
        <>
          <p>
            Every week, they gather in groups called Cub Packs to take part in
            lots of interesting and challenging activities - achieving anything
            they set their minds to, and having lots of fun along the way.
          </p>
          <p>
            From making new friends to mastering new skills, Cubs step foot on
            an adventure of opportunity. Cubs get the chance to try a number of
            new things, from swimming to cycling, and from exploring to
            collecting.
          </p>
          <p>
            Cubs are naturally curious of the world around them, and so will
            find themselves on trips to discover places a little further from
            their neighbourhood.
          </p>
          <p>
            Sound like fun? That&apos;s because it is. All that&apos;s missing
            is you.
          </p>
        </>
      </SectionIntroduction>
      <SectionDetails
        data={detailData}
        formation='pyramid'
        onCardChange={setOpenCardIndexes}
      />
      <SectionContact section='Cubs' />
    </>
  );
};

export default Cubs;
