import React from 'react';
import ContactCard from '../../../components/section-card/contact/contact-card';
import { css } from 'aphrodite';
import { commonStyles } from '../../../styles/common.styles';
import { useScreenSizeContext } from '../../../util/screensize.context';
import homeSectionsStyles from '../../home/sections/home-sections.styles';
import contactDetailsStyles from './contact-details.styles';
import { useLocation } from 'react-router-dom';

const ContactDetails = () => {
  const { isScreenXLarge, isScreenMediumOrLess, screenWidth } =
    useScreenSizeContext();

  const columnBreakpoint = React.useMemo(
    () => screenWidth < 700,
    [screenWidth],
  );

  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === '#gsl') {
      document
        ?.getElementById('gsl')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <div
      className={css(
        commonStyles.screenPadding,
        contactDetailsStyles.background,
      )}
    >
      <div
        className={
          isScreenXLarge
            ? css(commonStyles.flex, commonStyles.centreHorizontal)
            : undefined
        }
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapMedium,
            commonStyles.centreVertical,
            homeSectionsStyles.cardsContainer,
          )}
        >
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreHorizontal,
              commonStyles.centreVertical,
              commonStyles.fullWidth,
              isScreenXLarge
                ? commonStyles.gapXXLarge
                : columnBreakpoint
                  ? commonStyles.gapMedium
                  : commonStyles.spaceEvenly,

              columnBreakpoint && commonStyles.flexColumn,
            )}
          >
            <ContactCard variant='GSL' id={'gsl'} />
            <ContactCard variant='AGSL' />
          </div>
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreHorizontal,
              commonStyles.centreVertical,
              commonStyles.fullWidth,
              isScreenXLarge
                ? commonStyles.gapXXLarge
                : columnBreakpoint
                  ? commonStyles.gapMedium
                  : commonStyles.spaceEvenly,

              columnBreakpoint && commonStyles.flexColumn,
            )}
          >
            <ContactCard variant='Publicity' />
            <ContactCard variant='Chairperson' />
          </div>
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreVertical,
              commonStyles.fullWidth,
              isScreenXLarge
                ? commonStyles.gapXXLarge
                : columnBreakpoint
                  ? commonStyles.gapMedium
                  : commonStyles.spaceEvenly,
              columnBreakpoint && commonStyles.flexColumn,
            )}
          >
            <ContactCard variant='Squirrels' />
            <ContactCard variant='Beavers' />
            {!isScreenMediumOrLess && <ContactCard variant='Cubs' />}
          </div>
          {isScreenMediumOrLess && (
            <div
              className={css(
                commonStyles.flex,
                commonStyles.centreVertical,
                commonStyles.fullWidth,
                isScreenXLarge
                  ? commonStyles.gapXXLarge
                  : columnBreakpoint
                    ? commonStyles.gapMedium
                    : commonStyles.spaceEvenly,
                columnBreakpoint && commonStyles.flexColumn,
              )}
            >
              <ContactCard variant='Cubs' />
              <ContactCard variant='Scouts' />
            </div>
          )}
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreVertical,
              commonStyles.fullWidth,
              isScreenXLarge
                ? commonStyles.gapXXLarge
                : columnBreakpoint
                  ? commonStyles.gapMedium
                  : commonStyles.spaceEvenly,

              columnBreakpoint && commonStyles.flexColumn,
            )}
          >
            {!isScreenMediumOrLess && <ContactCard variant='Scouts' />}
            <ContactCard variant='Explorers' />
            <ContactCard variant='Network' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
