import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../styles/common.styles';
import toggleStyles from './toggle.styles';

export type ToggleProps = {
  enabled: boolean;
  disabledLabel?: string;
  enabledLabel?: string;
  onClick?: () => void;
};

const Toggle = ({
  enabled,
  disabledLabel,
  enabledLabel,
  onClick,
}: ToggleProps) => {
  return (
    <div
      className={css(
        commonStyles.flex,
        commonStyles.centreHorizontal,
        commonStyles.centreVertical,
        commonStyles.gapMedium,
      )}
    >
      {disabledLabel && (
        <div className={css(toggleStyles.label)}>{disabledLabel}</div>
      )}
      <button
        className={css(
          toggleStyles.toggleContainer,
          toggleStyles.transition,
          commonStyles.clickable,
          enabled
            ? toggleStyles.toggleContainerEnabled
            : toggleStyles.toggleContainerDisabled,
        )}
        onClick={onClick}
      >
        <div
          className={css(
            toggleStyles.toggleHandle,
            toggleStyles.transition,
            enabled && toggleStyles.toggleHandleEnabled,
          )}
        />
      </button>
      {enabledLabel && (
        <div className={css(toggleStyles.label)}>{enabledLabel}</div>
      )}
    </div>
  );
};

export default Toggle;
