import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

export const mobileNavStyles = StyleSheet.create({
  navBase: {
    position: 'fixed',
    padding: '28px',
    zIndex: theme.layers.mobileNav,
    backgroundColor: theme.colour.purple,
    transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease',
    width: 'calc(100% - 56px)',
    height: 'calc(100% - 56px)',
    color: theme.colour.white,
    overflow: 'scroll',
  },
  navHidden: {
    opacity: 0,
    visibility: 'hidden',
  },
  navVisible: {
    opacity: 1,
    visibility: 'visible',
  },
  navControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `0 ${theme.size.small}`,
  },
  navSectionHeading: {
    fontSize: '36px',
    fontWeight: 800,
    margin: `${theme.size.large} ${theme.size.small} ${theme.size.small} ${theme.size.small}`,
  },
  navItem: {
    fontSize: '28px',
  },
  navItemSubtitle: {
    textAlign: 'left',
  },
  navItemContainer: {
    margin: `0 ${theme.size.small}`,
  },
});
