import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

export const layoutStyles = StyleSheet.create({
  navItem: {
    alignItems: 'center',
    transition: 'color 0.2s 0.1s ease',
  },
  navItemDisabled: {
    color: theme.colour.grey['40'],
  },
  navItemEnabled: {
    color: theme.colour.white,
  },
  navItemEnabledScrolled: {
    color: theme.colour.black,
  },
  navItemContainer: {
    margin: `${theme.size.medium} 0`,
    gap: '42px',
    fontSize: '20px',
    height: 'fit-content',
    alignItems: 'center'
  },
  navItemsContainerScrolled: {
    alignItems: 'center',
  },
  navbar: {
    width: 'calc(100% - 8vw)',
    zIndex: theme.layers.navbar,
    padding: '2vw 4vw',
    position: 'fixed',
    transition: 'background-color 0.1s ease-in-out',
  },
  navbarScrolled: {
    backgroundColor: theme.colour.offWhite,
    padding: '1vw 4vw',
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: '3vw 4vw'
    }
  },
  logoTransition: {
    transition: 'width 0.2s ease-in-out',
  },
  logoContainer: {
    width: '26vw',
    [`@media (min-width: ${theme.breakpoints.large}px)`]: {
      width: '18vw',
    },
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      width: '35vw',
    },
  },
  logoContainerScrolled: {
    width: '14vw',
    [`@media (min-width: ${theme.breakpoints.small}px) and (max-width: ${theme.breakpoints.large}px)`]: {
      width: '24vw',
    },
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      width: '32vw',
    },
  },
  navbarShadow: {
    boxShadow: `0px 15px 26px -15px rgba(0,0,0,0.75)`,
  }
});
