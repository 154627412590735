import React from 'react';
import BaseSectionCard from '../base/base-section-card';
import { ContactData, ContactType, SectionStyle } from '../../../types/general';
import { useScreenSizeContext } from '../../../util/screensize.context';
import ContactCardContent from './content/contact-card-content';
import { getContactData } from '../../../util/data/contact';

export type ContactCardProps = {
  variant: ContactType;
  id?: string;
};

const isGeneralContactCard = (contactType: ContactType) => {
  return ['GSL', 'AGSL', 'Chairperson', 'Publicity'].includes(contactType);
};

const contactTypeToSectionCardType = (
  contactType: ContactType,
): SectionStyle => {
  if (isGeneralContactCard(contactType)) {
    return 'General';
  }

  return contactType as SectionStyle;
};

const ContactCard = ({ variant, id }: ContactCardProps) => {
  const cardVariant = React.useMemo(
    () => contactTypeToSectionCardType(variant),
    [variant],
  );
  const isGeneralCard = React.useMemo(
    () => isGeneralContactCard(variant),
    [cardVariant],
  );

  const { isScreenXLarge, isScreenMediumOrLess, screenWidth } =
    useScreenSizeContext();

  const columnBreakpoint = React.useMemo(
    () => screenWidth < 700,
    [screenWidth],
  );

  const getCardWidth = () => {
    if (isScreenXLarge) {
      return '24vw';
    } else if (columnBreakpoint) {
      return '300px';
    } else if (isScreenMediumOrLess) {
      return '38vw';
    } else {
      return '24vw';
    }
  };

  const cardWidth = React.useMemo(
    () => getCardWidth(),
    [isScreenXLarge, columnBreakpoint, isScreenMediumOrLess],
  );

  const getCardHeight = () => {
    if (columnBreakpoint) {
      return isGeneralCard ? '130px' : '160px';
    } else if (isScreenMediumOrLess) {
      return isGeneralCard ? '15vw' : '20vw';
    } else {
      return isGeneralCard ? 'calc(6.5vw + 6.5vh)' : 'calc(8vw + 8vh)';
    }
  };

  const cardHeight = React.useMemo(
    () => getCardHeight(),
    [columnBreakpoint, isScreenMediumOrLess, isGeneralCard],
  );

  const getCardPadding = () => {
    if (columnBreakpoint) {
      return '4vw';
    } else if (isScreenMediumOrLess) {
      return '2.5vw';
    } else {
      return '1.5vw';
    }
  };

  const cardPadding = React.useMemo(
    () => getCardPadding(),
    [columnBreakpoint, isScreenMediumOrLess],
  );

  return (
    <BaseSectionCard
      variant={cardVariant}
      width={cardWidth}
      height={cardHeight}
      customPadding={cardPadding}
      centre
      id={id}
    >
      <ContactCardContent
        contactData={getContactData(variant) as ContactData}
      />
    </BaseSectionCard>
  );
};

export default ContactCard;
