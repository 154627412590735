import React from 'react';
import Card from '../card/card';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../styles/theme';
import collapsibleCardStyles from './collapsible-card.styles';
import { useScreenSizeContext } from '../../util/screensize.context';
import { useSessionContext } from '../../util/session.context';

export type CollapsibleCardProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  id?: string;
  // value must be equal on all sides (i.e. only 1 value provided: `26px` or `2vw`, NOT `26px 18px` or `2vw 3vw 5vw 6vw`)
  padding?: string;
  fullWidth?: boolean;
  // override for determining if the card is open or not. If this is provided, clicking on the card has no effect
  open?: boolean;
  onClick?: () => void;
};

const CollapsibleCard = ({
  title,
  content,
  id,
  padding,
  fullWidth = true,
  open,
  onClick,
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = React.useState(open ?? false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const { screenWidth } = useScreenSizeContext();
  const { isMobileMenuOpen } = useSessionContext();

  const maxHeight = React.useMemo(
    () => contentRef.current?.scrollHeight,
    [contentRef.current, screenWidth, isOpen],
  );

  React.useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <Card padding={'0'} fullWidth={fullWidth} id={id}>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.gapSmall,
          commonStyles.clickable,
        )}
        onClick={
          open === undefined
            ? () => {
                setIsOpen(!isOpen);
                if (onClick) {
                  onClick();
                }
              }
            : onClick
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (open === undefined) {
              setIsOpen(!isOpen);
              if (onClick) {
                onClick();
              }
            } else {
              if (onClick) {
                onClick();
              }
            }
          }
        }}
        style={{ padding }}
        tabIndex={isMobileMenuOpen ? -1 : 0}
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.centreHorizontal,
            commonStyles.centreVertical,
          )}
          style={{ fontSize: '26px', color: theme.colour.purple }}
        >
          <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
        </div>
        <div>{title}</div>
      </div>
      <div
        ref={contentRef}
        className={css(collapsibleCardStyles.contentContainer)}
        style={{
          maxHeight: isOpen ? `${maxHeight}px` : 0,
          padding: isOpen ? padding : `0 ${padding}`,
        }}
      >
        <div>{content}</div>
      </div>
    </Card>
  );
};

export default CollapsibleCard;
