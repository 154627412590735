import { StyleSheet } from 'aphrodite';

const getUnderlineLinkStyles = (textColour: string) => {
  return StyleSheet.create({
    underlineAnimationText: {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
      paddingBottom: '2px',
      color: `${textColour}`,

      ':after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '0.1em',
        backgroundColor: `${textColour}`,
        transform: 'scaleX(0)',
        transformOrigin: 'bottom left',
        transition: 'transform 0.3s ease-out',
      },
      ':hover:after': {
        transform: 'scaleX(1)',
        transformOrigin: 'bottom left',
      },
    },
  });
};

export default getUnderlineLinkStyles;
