import { PageMetadata, PageMetadataStore } from "../../types/general";
import { Page } from "../../types/navigation";
import { media } from "../media";

export const meta: PageMetadataStore = {
    '/': { page: Page.Home },
    '/squirrels': {
        page: Page.Squirrels,
        title: 'Squirrels',
        description: '2nd Rugeley Squirrel Drey meet on Thursdays from 17:30 - 18:30. Every week, Squirrels will gather to grow and learn their way through lots of different games and activities',
        openGraph: {
            img: media.images.sections.squirrels.header[0].src
        }
    },
    '/beavers': {
        page: Page.Beavers,
        title: 'Beavers',
        description: '2nd Rugeley Beaver Colony meet on Wednesdays from 18:15 - 19:30. Try new things. Make new friends. Joining Beavers is just the beginning of your big adventure.',
        openGraph: {
            img: media.images.sections.beavers.header[0].src
        }
    },
    '/cubs': {
        page: Page.Cubs,
        title: 'Cubs',
        description: '2nd Rugeley Cub Pack meet on Tuesdays from 18:15 - 19:45. Develop new skills. Soar to great heights. Being a Cub opens up a whole other world.',
        openGraph: {
            img: media.images.sections.cubs.header[0].src
        },
    },
    '/scouts': {
        page: Page.Scouts,
        title: 'Scouts',
        description: '2nd Rugeley Scout Troop meet on Mondays from 19:00 - 21:00. Develop new skills. Jump in and get muddy. Give back and get set. Scouts ignore the butterflies and go for it, and soon so will you.',
        openGraph: {
            img: media.images.sections.scouts.header[0].src
        }
    },
    '/explorers': {
        page: Page.Explorers,
        title: 'The Stags Explorers',
        description: `The Stags Explorer Scout Unit meet on Thudays from 19:30 - 21:30. Try more. See more. Do more. At Explorers you'll get the chance to dream big, find your place in the world and make memories to last a lifetime.`,
        openGraph: {
            img: media.images.sections.explorers.header[0].src
        }
    },
    '/network': {
        page: Page.Network,
        title: 'Cannock Chase District Network',
        description: `Try more. See more. Do more. At Scout Network, you'll stand on your own two feet, and make memories to last a lifetime.`,
        openGraph: {
            img: media.images.sections.network.header[0].src
        }
    },
    '/volunteer': {
        page: Page.Volunteer,
        title: 'Volunteer',
        description: `You look like a role model. Whatever your background, identity or ability, there's a volunteer spot with your name on it.`,
        openGraph: {
            img: media.images.background.campfire.src
        }
    },
    '/contact': {
        page: Page.Contact,
        title: 'Contact Us',
        description: `Get In Touch. We're here to help you embark on you or your child's Scouting adventure.`,
        openGraph: {
            img: media.images.background.campfire.src
        }
    },
    '/join': {
        page: Page.Join,
        title: 'Contact Us',
        description: `Enrol in one of our sections, or become a leader. Are you ready to join the adventure?`,
        openGraph: {
            img: media.images.background.zipline.src
        }
    },
    '/404': {
        page: Page.NotFound,
        title: 'Not Found',
        crawl: false,
    }
}

export const metaDefault: PageMetadata = {
    page: Page.Home,
    title: '2nd Rugeley Scout Group',
    description: `We are 2nd Rugeley Scout Group, and we've been preparing young people with skills for life for 50 years`,
    openGraph: {
        img: media.images.background.campfire.src,
    },
    crawl: true
}